import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaInfoCircle, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import './AddOns.css';

function AddOns({ addOns, onAddOnToggle }) {
  const [visibleStartIndex, setVisibleStartIndex] = useState(0);
  const visibleCount = 2;

  const handleNext = () => {
    if (visibleStartIndex + visibleCount < addOns.length) {
      setVisibleStartIndex(prevIndex => prevIndex + visibleCount);
    }
  };

  const handlePrevious = () => {
    if (visibleStartIndex > 0) {
      setVisibleStartIndex(prevIndex => prevIndex - visibleCount);
    }
  };

  return (
    addOns.length > 0 && (
      <div className="add-ons">
        <h2 className="add-ons-title">Add-ons</h2>
        <div className="add-ons-wrapper">
          <motion.button
            className="scroll-button"
            onClick={handlePrevious}
            disabled={visibleStartIndex === 0}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaChevronLeft />
          </motion.button>
          <div className="add-on-container">
            <AnimatePresence initial={false} mode="popLayout">
              {addOns.slice(visibleStartIndex, visibleStartIndex + visibleCount).map((addon) => (
                <motion.div
                  key={addon.id}
                  className="add-on-item"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{ duration: 0.3 }}
                  layout
                >
                  <div className="add-on-info">
                    <FaInfoCircle className="info-icon" />
                    <div>
                      <h3>{addon.addon_desc}</h3>
                      <p>{addon.description}</p>
                      <p className="price">₹{addon.addon_sp}/-</p>
                    </div>
                  </div>
                  <motion.button 
                    className={`add-button ${addon.selected ? 'selected' : ''}`}
                    onClick={() => onAddOnToggle(addon.id)}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    {addon.selected ? 'Remove' : 'Add'}
                  </motion.button>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
          <motion.button
            className="scroll-button"
            onClick={handleNext}
            disabled={visibleStartIndex + visibleCount >= addOns.length}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaChevronRight />
          </motion.button>
        </div>
      </div>
    )
  );
}

export default AddOns;

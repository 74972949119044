import React, { useState, useEffect, useCallback, useMemo } from "react";
import "./Explore.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCompass,
  faAngleRight,
  faAngleDown,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { ApiHitPost } from "../../../../../Services/ApiHit";

// added explore redirections functional with skip functionality@Satyam

const VerticalMenu = React.memo(({ items, groupName, subText, onSelect, onClick, activeItem, hoverItem }) => {
  return (
    <div className="vertical-menu">
      <h3>{groupName}</h3>
      <p>{subText}</p>
      <div className="vertical-menu-item-div scrollable-container">
        {items.map((item, index) => (
          <button
            key={index}
            onMouseOver={() => onSelect(item)}
            onClick={() => onClick(item)}
            className={`parent-category ${activeItem === item ? "active" : ""} ${hoverItem === item ? "hover" : ""}`}
          >
            <img
              src={item.category_thumbnail_image}
              alt={item.category_name_english}
              className="category-thumbnail"
            />
            {item.category_name_english}
            <FontAwesomeIcon icon={faAngleRight} className="arrow-icon" />
          </button>
        ))}
      </div>
    </div>
  );
});

const HorizontalMenu = React.memo(({ title, subText, items, onSelect, onClick, activeItem, hoverItem }) => {
  return (
    <div className="horizontal-menu">
      <h3>{title}</h3>
      <p>{subText}</p>
      <div className="horizontal-menu-item-div scrollable-container">
        {items.map((item, index) => (
          <button
            key={index}
            onMouseOver={() => onSelect(item)}
            onClick={() => onClick(item)}
            className={`${activeItem === item ? "active" : ""} ${hoverItem === item ? "hover" : ""}`}
          >
            <img
              src={item.category_thumbnail_image}
              alt={item.category_name_english}
              className="category-thumbnail"
            />
            {item.category_name_english}
            {item.child && item.child.length > 0 && (
              <FontAwesomeIcon icon={faAngleRight} className="arrow-icon" />
            )}
          </button>
        ))}
      </div>
    </div>
  );
});
// fixed the Explore Apperance in WebView @Satyam
const MobileMenu = React.memo(({ items, onBack, level = 0, activeItem, setActiveItem, onClick }) => {
  const [expandedItems, setExpandedItems] = useState({});

  const handleItemClick = (index, item) => {
    if (item.child && item.child.length > 0) {
      setExpandedItems((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));
      setActiveItem(item);
    } else {
      onClick(item);
    }
  };

  return (
    <div className="mobile-menu">
      <h3>
        {level === 0
          ? "Choose Your Vertical"
          : items[0]?.child_group_name_english}
      </h3>
      <p>{items[0]?.sub_text}</p>
      <div className="scrollable-container">
        {items.map((item, index) => (
          <div key={index} className="mobile-menu-item">
            <button
              onClick={() => handleItemClick(index, item)}
              className={activeItem === item ? "active" : ""}
            >
              <img
                src={item.category_thumbnail_image}
                alt={item.category_name_english}
                className="category-thumbnail"
              />
              {item.category_name_english}
              {item.child && item.child.length > 0 && (
                <FontAwesomeIcon
                  icon={expandedItems[index] ? faAngleDown : faAngleRight}
                  className="arrow-icon"
                />
              )}
            </button>
            {item.child && item.child.length > 0 && expandedItems[index] && (
              <div className="sub-menu">
                <MobileMenu
                  items={item.child}
                  onBack={() => handleItemClick(index, item)}
                  level={level + 1}
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                  onClick={onClick}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
});

const Explore = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [explorePath, setExplorePath] = useState({ child: [] });
  const [isMobile, setIsMobile] = useState(false);
  const [selectedVertical, setSelectedVertical] = useState(null);
  const [selectedExam, setSelectedExam] = useState(null);
  const [selectedMode, setSelectedMode] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [hoverItem, setHoverItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const getExplorePaths = async () => {
      setIsLoading(true);
      try {
        const response = await ApiHitPost("/hierarchy/all");
        setExplorePath(response.data);
      } catch (error) {
        console.error("Error fetching explore paths:", error);
      } finally {
        setIsLoading(false);
      }
    };
    getExplorePaths();
  }, []);

  const toggleMenu = useCallback(() => {
    setMenuVisible((prev) => !prev);
  }, []);

  const handleVerticalSelect = useCallback((vertical) => {
    setHoverItem(vertical);
    setSelectedVertical(vertical);
    setSelectedExam(null);
    setSelectedMode(null);
  }, []);

  const handleVerticalClick = useCallback((vertical) => {
    setActiveItem(vertical);
    setSelectedVertical(vertical);
    setSelectedExam(null);
    setSelectedMode(null);
  }, []);

  const handleExamSelect = useCallback((exam) => {
    setHoverItem(exam);
    if (exam.child && exam.child.length > 0) {
      setSelectedExam(exam);
      setSelectedMode(null);
    }
  }, []);

  const handleExamClick = useCallback((exam) => {
    setActiveItem(exam);
    if (exam.child && exam.child.length > 0) {
      setSelectedExam(exam);
      setSelectedMode(null);
    } else {
      // Set the localStorage for selected path
      localStorage.setItem('dla_web_student_selected_hierarchy', exam.hierarchy);
      window.location.href = `/explore`;
    }
  }, []);

  const handleModeSelect = useCallback((mode) => {
    setHoverItem(mode);
    setSelectedMode(mode);
  }, []);

  const handleModeClick = useCallback((mode) => {
    setActiveItem(mode);
    setSelectedMode(mode);
    // Set the localStorage for selected path
    localStorage.setItem('dla_web_student_selected_hierarchy', mode.hierarchy);
    window.location.href = `/explore`;
  }, []);

  const memoizedVerticalMenu = useMemo(() => (
    <VerticalMenu
      items={explorePath.child}
      groupName={explorePath.group_name}
      subText={explorePath.sub_text}
      onSelect={handleVerticalSelect}
      onClick={handleVerticalClick}
      activeItem={activeItem}
      hoverItem={hoverItem}
    />
  ), [explorePath.child, handleVerticalSelect, handleVerticalClick, activeItem, hoverItem]);

  const memoizedHorizontalMenus = useMemo(() => (
    <>
      {selectedVertical && (
        <HorizontalMenu
          title={selectedVertical.child_group_name_english}
          subText={selectedVertical.sub_text}
          items={selectedVertical.child}
          onSelect={handleExamSelect}
          onClick={handleExamClick}
          activeItem={activeItem}
          hoverItem={hoverItem}
        />
      )}
      
      {selectedExam && selectedExam.child && selectedExam.child.length > 0 && (
        <HorizontalMenu
          title={selectedExam.child_group_name_english}
          subText={selectedExam.sub_text}
          items={selectedExam.child}
          onSelect={handleModeSelect}
          onClick={handleModeClick}
          activeItem={activeItem}
          hoverItem={hoverItem}
        />
      )}

      {selectedMode && selectedMode.child && selectedMode.child.length > 0 && (
        <HorizontalMenu
          title={selectedMode.child_group_name_english}
          subText={selectedMode.sub_text}
          items={selectedMode.child}
          onSelect={() => {}}
          onClick={handleModeClick}
          activeItem={activeItem}
          hoverItem={hoverItem}
        />
      )}
    </>
  ), [selectedVertical, selectedExam, selectedMode, handleExamSelect, handleExamClick, handleModeSelect, handleModeClick, activeItem, hoverItem]);

  return (
    <div className={`explore-container ${isMobile ? "mobile" : ""}`}>
      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
          <span className="loader-text">Loading...</span>
        </div>
      ) : (
        <>
          <button className="explore-button" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faCompass} /> Explore
          </button>
          {isMenuVisible && (
            <div className={`explore-menu-overlay ${isMenuVisible ? 'visible' : ''}`} onClick={toggleMenu}>
              <div
                className={`explore-menu ${isMobile ? "mobile" : ""} ${isMenuVisible ? 'visible' : ''}`}
                onClick={(e) => e.stopPropagation()}
              >
                {isMobile ? (
                  <MobileMenu
                    items={explorePath.child}
                    onBack={toggleMenu}
                    activeItem={activeItem}
                    setActiveItem={setActiveItem}
                    onClick={handleExamClick}
                  />
                ) : (
                  <>
                    {memoizedVerticalMenu}
                    {memoizedHorizontalMenus}
                  </>
                )}
                <button className="close-button" onClick={toggleMenu}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Explore;
import React from 'react';

const OurCentres = () => {
  return (
    <div className="bg-light-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-2">
          <span className="text-blue-600">Our Centres</span> across India
        </h2>
        <p className="text-center text-light-600 mb-8 max-w-2xl mx-auto">
          Explore Tech-Enabled Offline Drishti Centres Creating new benchmarks in learning experiences
        </p>
        <p className="text-center text-lg font-semibold mb-8">
          Find Drishti Centre in your city. Available in 6 cities.
        </p>
        <div className="relative w-full h-[400px] sm:h-[500px] lg:h-[700px]">
          <img
            src="/images/Dashboard/Frame 2147224310.png"
            alt="Map of India"
            className="w-full h-full object-contain"
          />
        </div>
      </div>
    </div>
  );
};

export default OurCentres;

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// GET Request 
export const ApiHitGet = async (UrlToHit) => {
  try {
    var api = axios.create({
      baseURL: `${API_BASE_URL}`,
      headers: {
        'Content-Type': 'application/json',
        'device-type': '3'
      },
    });
    const response = await api.get(UrlToHit);
    return response.data;
  } catch (error) {
    console.error('Error GET data:', error);
    throw error;
  }
};

// POST Request 
export const ApiHitPost = async (UrlToHit,PayloadData = {},HeaderData = {}) => {
  try {
    var AuthorizationHeader = {};
    if(localStorage.getItem("dla_web_student_u") !== null){
      const Token = localStorage.getItem("dla_web_student_u");
      AuthorizationHeader = {
        'Authorization': `Bearer ${Token}`
      }
    }
    var api = axios.create({
      baseURL: `${API_BASE_URL}`,
      headers: {
        'Content-Type': 'application/json',
        'device-type': '3',
        ...HeaderData,
        ...AuthorizationHeader
      },
    });
    const response = await api.post(UrlToHit, PayloadData);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      if(localStorage.getItem('dla_web_student_u') !== null){
        HandleLogout();
      }
    }
    console.error('Error POST data:', error);
    throw error;
  }
};

const HandleLogout = async () => {
  try {
    await ApiHitPost('/auth/logout')
    .then(response => {
      if (response.status === 'SUCCESS') {
        clearLocalStorage();
        window.location.reload();
      }else{
        console.log(response.message || 'Error In Logout!.');
      }
    }).catch(error => {
      console.log(error);
      if (error.response && error.response.status && error.response.status === 401) {
        clearLocalStorage();
        window.location.reload();
      }
    });
  } catch (error) {
    console.log('Unable to Logout ',error);
  }
}

function clearLocalStorage() {
  localStorage.removeItem('dla_web_student_u');
  localStorage.removeItem('dla_web_student_r');
  localStorage.removeItem('dla_web_student_first_name');
  localStorage.removeItem('dla_web_student_last_name');
  localStorage.removeItem('dla_web_student_profile_picture');
  localStorage.removeItem('dla_web_student_selected_vertical');
  localStorage.removeItem('dla_web_student_email_id');
  localStorage.removeItem('dla_web_student_gender');
}

import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import './AddressModal.css';
import { ApiHitPost } from '../../../../../Services/ApiHit';

const states = [
  { id: 1632, name: 'Andaman and Nicobar Islands' },
  { id: 1633, name: 'Andhra Pradesh' },
  { id: 1634, name: 'Arunachal Pradesh' },
  { id: 1635, name: 'Assam' },
  { id: 1636, name: 'Bihar' },
  { id: 1637, name: 'Chandigarh' },
  { id: 1638, name: 'Chhattisgarh' },
  { id: 1639, name: 'Dadra and Nagar Haveli and Daman and Diu' },
  { id: 1640, name: 'Delhi' },
  { id: 1641, name: 'Goa' },
  { id: 1642, name: 'Gujarat' },
  { id: 1643, name: 'Haryana' },
  { id: 1644, name: 'Himachal Pradesh' },
  { id: 1645, name: 'Jammu and Kashmir' },
  { id: 1646, name: 'Jharkhand' },
  { id: 1647, name: 'Karnataka' },
  { id: 1648, name: 'Kerala' },
  { id: 1649, name: 'Ladakh' },
  { id: 1650, name: 'Lakshadweep' },
  { id: 1651, name: 'Madhya Pradesh' },
  { id: 1652, name: 'Maharashtra' },
  { id: 1653, name: 'Manipur' },
  { id: 1654, name: 'Meghalaya' },
  { id: 1655, name: 'Mizoram' },
  { id: 1656, name: 'Nagaland' },
  { id: 1657, name: 'Odisha' },
  { id: 1658, name: 'Puducherry' },
  { id: 1659, name: 'Punjab' },
  { id: 1660, name: 'Rajasthan' },
  { id: 1661, name: 'Sikkim' },
  { id: 1662, name: 'Tamil Nadu' },
  { id: 1663, name: 'Telangana' },
  { id: 1664, name: 'Tripura' },
  { id: 1665, name: 'Uttar Pradesh' },
  { id: 1666, name: 'Uttarakhand' },
  { id: 1667, name: 'West Bengal' },
];

function AddressModal({ onClose, onAddAddress, addresses, onSelectAddress, selectedAddress }) {
  const [newAddress, setNewAddress] = useState({
    line_1_input: '',
    line_2_input: '',
    town_input: '',
    state_input: '',
    pincode_input: '',
    mobile_no_input: '', // Added mobile number field
  });

  const [isVisible, setIsVisible] = useState(false);
  const [isAddressListCollapsed, setIsAddressListCollapsed] = useState(true);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setNewAddress((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const alphanumericRegex = /^[a-zA-Z0-9\s]{3,50}$/;
  
    if (
      !alphanumericRegex.test(newAddress.line_1_input) ||
      !alphanumericRegex.test(newAddress.line_2_input) ||
      !alphanumericRegex.test(newAddress.town_input) ||
      !alphanumericRegex.test(newAddress.pincode_input) ||
      !alphanumericRegex.test(newAddress.mobile_no_input)
    ) {
      alert('Input fields must be 3-50 alphanumeric characters with no special characters.');
      return;
    }
  
    if (newAddress.state_input === '') {
      alert('Please select a state.');
      return;
    }
  
    try {
      await ApiHitPost('/user/add_address', {
        line1: newAddress.line_1_input,
        line2: newAddress.line_2_input,
        pincode: newAddress.pincode_input,
        state_id: Number(newAddress.state_input), // State ID is used here
        town: newAddress.town_input,
        mobile_number: newAddress.mobile_no_input, // Send mobile number to API
        landmark: '', 
      });
  
      onAddAddress(newAddress);
      setNewAddress({
        line_1_input: '',
        line_2_input: '',
        town_input: '',
        state_input: '',
        pincode_input: '',
        mobile_no_input: '', // Reset mobile number field
      });
      setIsAddressListCollapsed(false);
      
      // Reload the page on success
      window.location.reload();
    } catch (error) {
      console.error('Error adding address:', error);
      // alert('Error adding address');
    }
  };
  

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(onClose, 300);
  };

  const toggleAddressList = () => {
    setIsAddressListCollapsed(!isAddressListCollapsed);
  };

  return (
    <div className={`am-overlay ${isVisible ? 'am-overlay--visible' : ''}`} onClick={handleClose}>
      <div className={`am-content ${isVisible ? 'am-content--visible' : ''}`} onClick={(e) => e.stopPropagation()}>
        <button className="am-close-btn" onClick={handleClose}>
          &times;
        </button>
        <h2>Manage Addresses</h2>

        <div className="am-address-section">
          <div className="am-address-header" onClick={toggleAddressList}>
            <h3>Your Addresses</h3>
            <span className={`am-toggle-icon ${isAddressListCollapsed ? 'am-toggle-icon--collapsed' : ''}`}>▼</span>
          </div>
          {!isAddressListCollapsed && (
            <div className="am-address-list">
              {addresses.map((address) => (
                <div
                  key={address.id}
                  className={`am-address-item ${
                    selectedAddress && selectedAddress.id === address.id ? 'am-address-item--selected' : ''
                  }`}
                >
                  <div className="am-address-details">
                    <p>
                      <strong>{address.line1}</strong>
                    </p>
                    <p>{address.line2}</p>
                    <p>
                      {address.line2}, {address.state_name}, {address.pincode}
                    </p>
                  </div>
                  <button
                    className={`am-select-btn ${
                      selectedAddress && selectedAddress.id === address.id ? 'am-select-btn--selected' : ''
                    }`}
                    onClick={() => onSelectAddress(address)}
                  >
                    {selectedAddress && selectedAddress.id === address.id ? 'Selected' : 'Select'}
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        <h3>Add New Address</h3>
        <form onSubmit={handleSubmit} className="am-form">
            <input
            className="am-input"
            type="text"
            name="line_1_input"
            value={newAddress.line_1_input}
            onChange={handleInputChange}
            placeholder="Line 1"
            required
          />
          <input
            className="am-input"
            type="text"
            name="line_2_input"
            value={newAddress.line_2_input}
            onChange={handleInputChange}
            placeholder="Line 2"
            required
          />
          <div className="am-form-row">
            <input
              className="am-input"
              type="text"
              name="town_input"
              value={newAddress.town_input}
            onChange={handleInputChange}
            placeholder="Town"
            required
            />
          </div>


          <div className="am-form-row">
            <select
              className="am-input"
              name="state_input"
              value={newAddress.state_input}
              onChange={handleInputChange}
              required
            >
              <option value="">Select State</option>
              {states.map((state) => (
                <option key={state.id} value={state.id}>
                  {state.name}
                </option>
              ))}
            </select>
          </div>
          <input
            className="am-input"
            type="text"
            name="pincode_input"
            value={newAddress.pincode_input}
            onChange={handleInputChange}
            placeholder="ZIP Code"
            required
            pattern="[0-9]{6}"
          />
          <input
            className="am-input"
            type="tel"
            name="mobile_no_input"
            value={newAddress.mobile_no_input}
            onChange={handleInputChange}
            placeholder="Mobile Number"
            required
            pattern="[0-9]{10}"
          />
          <button type="submit" className="am-submit-btn">
            Add Address
          </button>
        </form>
      </div>
    </div>
  );
}

AddressModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAddAddress: PropTypes.func.isRequired,
  addresses: PropTypes.array.isRequired,
  onSelectAddress: PropTypes.func.isRequired,
  selectedAddress: PropTypes.object,
};

export default AddressModal;

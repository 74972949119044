import React, { useState } from 'react';
import { ApiHitPost } from '../../../../Services/ApiHit';
import './ProceedPayU.css';
import { useParams } from "react-router-dom";

const PaymentButton = ({ billSummaryData,addresses,selectedAddress }) => {
  const [loading, setLoading] = useState(false);
  const { productId, emiNo } = useParams();
  const handlePayment = async () => {
    setLoading(true);
    try {
      // Initialize transaction

      console.log("This is bill summary data",billSummaryData);
      let purchase_components = [];
      billSummaryData.course_basic_information.forEach(data => {
        purchase_components.push({
          emi_no: data.emi_no,
          product_id: data.course_id,
          product_price: data.course_selling_price,
        });
      });

      const couponId = billSummaryData.coupon_details?.coupon_id || 0;

      

      const cart_product_ids = billSummaryData.course_basic_information.map(val => val.course_title_english);

      // console.log("bjkask",cart_product_ids);
      // return false;

        if(!addresses){
            alert("Please Add a address for proceed");
            // setIsAddressListCollapsed(true);
            return false;

        }

        // console.log("selectedAddress",selectedAddress);
        // console.log("addresses",addresses);
        // return false;

      const initResponse = await ApiHitPost('/payment/initialize_transaction', {
        address_id: selectedAddress ? selectedAddress.id : addresses[0].id,
        cart_value: billSummaryData.net_payble_amount,
        coupon_applied: couponId,
        pay_via: billSummaryData.pay_via,
        product_addons: billSummaryData.addons_data,
        purchase_components: purchase_components,
      });

      const txnid = initResponse.data.data.pre_txn_id;
      const hash_array = initResponse.data.data.hash_array;
      const hash = hash_array.payment_hash;
    //   console.log(initResponse);
      console.log(hash_array);
// return false;
      if (initResponse) {
        // Ensure amount is a number and format it if necessary
        const amount = parseFloat(billSummaryData.net_payble_amount).toFixed(2);

        console.log("amount from web",amount);
        if (!amount || isNaN(amount)) {
          console.error("Invalid amount value:", billSummaryData.net_payble_amount);
        } else {
            // console.log("Final Amiunt Send to PG",amount);
        //   const response = await ApiHitPost('/payment/create-pay-order', {
        //     amount: amount, // Amount in INR, ensure it's a number or string
        //     firstname: localStorage.getItem('dla_web_student_first_name'),
        //     email: localStorage.getItem('dla_web_student_email_id'),
        //     phone: localStorage.getItem('dla_web_student_mobile_number').toString(),
        //     productinfo: '3619',
        //     txnid: init_txnid,
        //     surl: 'https://dla.frontbencher.in/api/payu-success', // Success URL
        //     furl: 'https://dla.frontbencher.in/api/payu-failed'  // Failure URL
        //   });

        //   const { txnid, hash, payuUrl } = response;

        //   console.log("order hash",hash);
        //   console.log("init hash",init_hash);

          // Create and submit form to PAYU payment gateway
          const form = document.createElement('form');
          form.method = 'POST';
          form.action = "https://secure.payu.in/_payment";

          const fields = {
            key: 'hpMct5', // Replace with your actual merchant key
            txnid,
            amount: amount,
            productinfo: cart_product_ids.join(','),
            firstname: localStorage.getItem('dla_web_student_first_name'),
            email: localStorage.getItem('dla_web_student_email_id'),
            phone: localStorage.getItem('dla_web_student_mobile_number') ? localStorage.getItem('dla_web_student_mobile_number').toString() : "",

            // phone: localStorage.getItem('dla_web_student_mobile_number').toString(),
            hash,
            surl: 'https://dla.frontbencher.in/api/payu-success', // Success URL
            furl: 'https://dla.frontbencher.in/api/payu-failed'  // Failure URL
          };

          console.log("there are fields",fields);
        //   return false;

          Object.entries(fields).forEach(([key, value]) => {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = value;
            form.appendChild(input);
          });

          document.body.appendChild(form);
          form.submit();
        }
      }
    } catch (error) {
      console.error('Error creating order:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <button className="continue-btn" onClick={handlePayment} disabled={loading}>
      {loading ? 'Processing...' : 'Continue Pay'}
    </button>
  );
};

export default PaymentButton;

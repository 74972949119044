import React from "react";
import styles from "./OtherInformation.module.css";

const OtherInformation = ({ otherInformation }) => {
  return (
    <div className={styles.informationCourse}>
      <div className={styles.infoRow}>
        <span className={styles.headingText}>Category:</span> 
        <span>{otherInformation.product_category_type_name}</span>
      </div>

      <div className={styles.infoRow}>
        <span className={styles.headingText}>Language:</span> 
        <span>{otherInformation.product_lang}</span>
      </div>

      <div className={styles.infoRow}>
        <span className={styles.headingText}>Validity:</span> 
        <span>{otherInformation.validity} Months</span>
      </div>
    </div>
  );
};

export default OtherInformation;

import React from "react";
import styles from "./PayEmiButton.module.css";

const PayEmiButton = ({ payEmiButtonText, handleAnotherEmiPaymentBuyNow }) => {
  return (
      <button 
        className={styles.PayEmiButton}
        onClick={handleAnotherEmiPaymentBuyNow}
      >
        {payEmiButtonText}
      </button>
    )
};

export default PayEmiButton;

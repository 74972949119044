import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const ClassCard = ({ imageUrl, category, title, duration }) => (
  <div className="w-full bg-white rounded-lg shadow-md overflow-hidden">
    <div className="relative">
      <img src={imageUrl} alt={title} className="w-full h-48 object-cover" />
      <div className="absolute top-2 right-2 bg-white rounded-full p-1 flex items-center">
        <span className="text-xs ml-1">{duration}</span>
      </div>
    </div>
    <div className="p-4">
      <span className="text-sm font-semibold text-blue-600">{category}</span>
      <h3 className="text-lg font-semibold mt-2 mb-4">{title}</h3>
    </div>
  </div>
);

const SampleClasses = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(3);
  const classes = [
    { imageUrl: "/images/Dashboard/delhibranch.png", category: "Ethics", title: "UPSC Interview", duration: "30 mins" },
    { imageUrl: "/images/Dashboard/karolbaghbranch.png", category: "Polity", title: "Lorem Ipsum", duration: "30 mins" },
    { imageUrl: "/images/Dashboard/lucknowbrnach.png", category: "Indian History", title: "Lorem Ipsum", duration: "30 mins" },
    { imageUrl: "/images/Dashboard/jaipurbranch.png", category: "Geography", title: "Lorem Ipsum", duration: "30 mins" },
    { imageUrl: "/images/Dashboard/indorebranch.png", category: "Economics", title: "Lorem Ipsum", duration: "30 mins" },
    { imageUrl: "/images/Dashboard/prayagrajbranch.png", category: "Science", title: "Lorem Ipsum", duration: "30 mins" },
  ];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setSlidesToShow(1);
      } else if (window.innerWidth < 1024) {
        setSlidesToShow(3);
      } else {
        setSlidesToShow(4);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const autoSlide = setInterval(() => {
      nextSlide();
    }, 3000);

    return () => clearInterval(autoSlide);
  }, [currentIndex, slidesToShow]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % classes.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + classes.length) % classes.length);
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="bg-light-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-2">Sample Classes</h2>
        <p className="text-center text-gray-600 mb-8 max-w-2xl mx-auto">
          Join our famous class, the knowledge provided will definitely be useful for you.
        </p>

        <div className="relative">
          <div className="overflow-hidden">
            <div
              className="flex transition-transform ease-out duration-500"
              style={{
                transform: `translateX(-${(currentIndex * 100) / slidesToShow}%)`,
                width: `${(classes.length * 100) / slidesToShow}%`,
              }}
            >
              {classes.map((classItem, index) => (
                <div
                  key={index}
                  className="flex-shrink-0 p-2"
                  style={{ width: `${100 / slidesToShow}%` }}
                >
                  <ClassCard {...classItem} />
                </div>
              ))}
            </div>
          </div>

          <button
            onClick={prevSlide}
            className="absolute left-0 top-1/2 -translate-y-1/2 bg-white p-2 rounded-full shadow-md"
          >
            <ChevronLeft size={24} />
          </button>

          <button
            onClick={nextSlide}
            className="absolute right-0 top-1/2 -translate-y-1/2 bg-white p-2 rounded-full shadow-md"
          >
            <ChevronRight size={24} />
          </button>

          <div className="flex justify-center mt-4">
            {classes.map((_, index) => (
              <button
                key={index}
                onClick={() => goToSlide(index)}
                className={`w-3 h-3 mx-2 rounded-full ${
                  index === currentIndex ? 'bg-blue-600' : 'bg-gray-300'
                }`}
              ></button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SampleClasses;

import React from 'react';
import { motion } from 'framer-motion';

const MaterialCard = ({ imageUrl, title, description }) => (
  <motion.div 
    className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
   
    <div className="mb-4">
      <img src={imageUrl} alt={title} className="w-34 h-24 object-cover" />
    </div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600 text-sm">{description}</p>
  </motion.div>
);

const StudyMaterial = () => {
  const materials = [
    {
      imageUrl: "/images/Dashboard/notes.png",
      title: "Notes",
      description: "Use Drishti's detailed study materials that simplify complex ideas into easily understandable language."
    },
    {
      imageUrl: "/images/Dashboard/refrencebooks.png",
      title: "Reference Books",
      description: "Our experts have created thorough study materials that break down complicated concepts into easily understandable content."
    },
    {
      imageUrl: "/images/Dashboard/ncertsolutions.png",
      title: "NCERT Solutions",
      description: "Unlock academic excellence with Drishti's NCERT Solutions which provides you step-by-step solutions."
    }
  ];

  return (
    <div className="bg-light-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-2">
          <span className="text-blue-600">Study</span> Material
        </h2>
        <p className="text-center text-light-600 mb-8 max-w-2xl mx-auto">
          Drishti provides a plethora of study content to help you clear tough exams and boost your motivation for future success.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {materials.map((material, index) => (
            <MaterialCard key={index} {...material} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StudyMaterial;

import React, { useState, useEffect } from 'react';
import './Address.css';
import AddressModal from './AddressModal/AddressModal';

function Address({ addresses, onSelectAddress, onAddAddress }) {
  // console.log(addresses);
  const [showModal, setShowModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(addresses ? addresses[0] : null);
// console.log("hwre isthe selected address",addresses[0]);
  useEffect(() => {
    if (addresses && addresses.length === 0) {
      setSelectedAddress(null);
    }
  }, [addresses]);

  const handleSelectAddress = (address) => {
    setSelectedAddress(address);
    onSelectAddress(address); 
  };

  return (
    <div className="address">
      <h2>Address</h2>
      <div className="address-content">
        {selectedAddress ? (
          <div className="address-info">
            <svg className="location-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z" fill="#4267B2"/>
            </svg>
            <div>
              <p className="name">{selectedAddress.line1}</p>
              <p>{selectedAddress.line2}</p>
              <p>{selectedAddress.city} {selectedAddress.state_name}, {selectedAddress.pincode}</p>
            </div>
          </div>
        ) : (
          <p>No address selected</p>
        )}
        <button className="change-button" onClick={() => setShowModal(true)}>
          {selectedAddress ? 'Change' : 'Add New Address'}
        </button>
      </div>

      {showModal && (
        <AddressModal
          onClose={() => setShowModal(false)}
          onAddAddress={onAddAddress}
          addresses={addresses}
          onSelectAddress={handleSelectAddress}
        />
      )}
    </div>
  );
}

export default Address;

import React, { useState, useEffect, useRef } from "react";
import "./DashboardBanners.css";

const DashboardBanners = ({ BannerInformation }) => {
  const [itemsPerSlide, setItemsPerSlide] = useState(3);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setItemsPerSlide(2);
      } else {
        setItemsPerSlide(4);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const HandleBannerClick = (Url) => {
    if (!isDragging) {
      window.location.href = Url;
    }
  };

  const scrollToIndex = (index) => {
    if (carouselRef.current) {
      const scrollAmount = index * (carouselRef.current.offsetWidth / itemsPerSlide);
      carouselRef.current.scrollTo({
        left: scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex + itemsPerSlide >= BannerInformation.length
        ? prevIndex
        : prevIndex + itemsPerSlide;
      scrollToIndex(nextIndex);
      return nextIndex;
    });
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex - itemsPerSlide < 0
        ? 0
        : prevIndex - itemsPerSlide;
      scrollToIndex(nextIndex);
      return nextIndex;
    });
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - carouselRef.current.offsetLeft);
    setScrollLeft(carouselRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - carouselRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    carouselRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].clientX - carouselRef.current.offsetLeft);
    setScrollLeft(carouselRef.current.scrollLeft);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const x = e.touches[0].clientX - carouselRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    carouselRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
    scrollToIndex(index);
  };

  const totalSlides = Math.ceil(BannerInformation.length / itemsPerSlide);

  const isNextDisabled = currentIndex + itemsPerSlide >= BannerInformation.length;
  const isPrevDisabled = currentIndex === 0;

  return (
    <div className="carousel-container-banners">
      <button 
        onClick={prevSlide} 
        className={`carousel-control-banners prev ${isPrevDisabled ? "disabled" : ""}`}
        disabled={isPrevDisabled}
      >
        &lt;
      </button>
      <div 
        className="carousel-content-banners" 
        ref={carouselRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {BannerInformation.map((OneBanner, index) => (
          <div
            key={index}
            className="carousel-item-banners"
            onClick={() =>
              HandleBannerClick(
                OneBanner.type === 2
                  ? OneBanner.web_url
                  : `/product-information/${OneBanner.product_id}`
              )
            }
          >
            <img src={OneBanner.image_url} alt="Banner" draggable="false" />
          </div>
        ))}
      </div>
      <button
        onClick={nextSlide}
        className={`carousel-control-banners next ${isNextDisabled ? "disabled" : ""}`}
        disabled={isNextDisabled}
      >
        &gt;
      </button>
      <div className="carousel-dots">
        {Array.from({ length: totalSlides }).map((_, index) => (
          <span
            key={index}
            className={`carousel-dot ${currentIndex === index * itemsPerSlide ? "active" : ""}`}
            onClick={() => goToSlide(index * itemsPerSlide)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default DashboardBanners;
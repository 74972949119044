import React from "react";
import "./OrderSummary.css";

function OrderSummary({ orders }) {
  // console.log("here is the data ",orders);

  const orderArray = Array.isArray(orders) ? orders : [orders];

  return (
    <div className="order-summary">
      <h2>Your order</h2>
      {orderArray.map((order, index) => (
        <div key={order.course_id || index} className="order-item">
          <div className="order-image">
            <img
              src={order.cover_image || " "}
              alt={order.course_title_english || "Course Image Not Found"}
            />
          </div>
          <div className="order-details">
            <h3>{order.course_title_english || "Untitled Course"}</h3>
            <div className="order-info">
              <span className="order-span">
                Price: <strong>₹{order.course_selling_price}</strong>
              </span>
              {/* <span className="order-span">
                Type: <strong>{order.product_type_text}</strong>
              </span> */}
               {/* <p>
                Course ID: <strong>{order.course_id}</strong>
              </p> */}
            </div>
           
            {order.is_emi && (
              <p>
                EMI Available: <strong>Yes</strong>
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default OrderSummary;

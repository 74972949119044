import React from 'react';
import { motion } from 'framer-motion';

const TestimonialCard = ({ name, rollNumber, rank, program, year, quote, imageUrl }) => (
  <motion.div 
    className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <motion.img
      src={imageUrl}
      alt={name}
      className="w-24 h-24 rounded-full mb-4 object-cover"
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ type: 'spring', stiffness: 100 }}
    />
    <h3 className="font-bold text-lg mb-1 text-gray-800">{name}</h3>
    <p className="text-sm text-gray-600 mb-1">Roll Number - {rollNumber}</p>
    <p className="text-sm text-blue-600 mb-2">Rank - {rank}</p>
    <p className="text-sm text-grey-600 mb-4">{program} - {year}</p>
    <p className="text-sm italic text-gray-700">"{quote}"</p>
  </motion.div>
);

const StudentTestimonials = () => {
  const testimonials = [
    {
      name: "Samyak S Jain",
      rollNumber: "2334820",
      rank: "7",
      program: "Interview Guidance Program",
      year: "2022",
      quote: "The program from GATE provided invaluable insights and strategies which was essential for my success",
      imageUrl: "/images/Dashboard/student1.png"
    },
    {
      name: "Gamini Singla",
      rollNumber: "2534510",
      rank: "3",
      program: "Interview Guidance Program",
      year: "2022",
      quote: "The program from GATE provided invaluable insights and strategies which was essential for my success",
      imageUrl: "/images/Dashboard/student2.png"
    },
    {
      name: "Yaksh Chaudhary",
      rollNumber: "2334520",
      rank: "5",
      program: "Interview Guidance Program",
      year: "2022",
      quote: "The program from GATE provided invaluable insights and strategies which was essential for my success",
      imageUrl: "/images/Dashboard/student3.png"
    }
  ];

  return (
    <div className="bg-light-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-2">
          <span className="text-blue-600">What our</span> students say about us.
        </h2>
        <p className="text-center text-light-600 mb-8 max-w-2xl mx-auto">
          Student loves our way of teaching, apart from excellent results in previous years we have great faculties and educational content.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} {...testimonial} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StudentTestimonials;

import React from 'react';
import {
  Container,
  Title,
  Subtitle,
  CountdownContainer,
  CountdownItem,
  CountdownLabel,
} from './ComingSoonStyles';

const ComingSoon = () => {

  const calculateTimeLeft = () => {
    const difference = +new Date('2024-09-01') - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <Container>
      <Title>Coming Soon</Title>
      <Subtitle>This Page is under Development!</Subtitle>
      <CountdownContainer>
        <CountdownItem>
          <span>{timeLeft.days}</span>
          <CountdownLabel>Days</CountdownLabel>
        </CountdownItem>
        <CountdownItem>
          <span>{timeLeft.hours}</span>
          <CountdownLabel>Hours</CountdownLabel>
        </CountdownItem>
        <CountdownItem>
          <span>{timeLeft.minutes}</span>
          <CountdownLabel>Minutes</CountdownLabel>
        </CountdownItem>
        <CountdownItem>
          <span>{timeLeft.seconds}</span>
          <CountdownLabel>Seconds</CountdownLabel>
        </CountdownItem>
      </CountdownContainer>
    </Container>
  );
};

export default ComingSoon;
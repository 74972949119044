import React, { useState, useEffect } from "react";
import "./PaymentFailed.css";
import { useLocation } from 'react-router-dom';


const OrderFailed = () => {
    const location = useLocation();
    const [postData, setPostData] = useState(null);

    useEffect(() => {

         // Check if no logged in user, redirect on login page
         const dla_web_student_u = localStorage.getItem("dla_web_student_u");
         if (dla_web_student_u === null) {
           localStorage.setItem('dla_web_student_redirect_back_to_login',`/my-cart`);
           window.location.href = '/login';
         }
         
        if (location.state) {
            // This is where you'd handle the "POST" data
            setPostData(location.state);
        }
    }, [location.state]);

    return (
        <div className="order-confirmation">
            <h1>Order Failed</h1>
            <div className="confirmation-gif">
                <img src={`${process.env.PUBLIC_URL}/images/failed.gif`} alt="Order Failed" />
            </div>
            <h2>Your Order has Failed</h2>
            <p>Your item was not successfully placed. Please try again.</p>
        </div>
    );
};

export default OrderFailed;

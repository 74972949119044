import React, { useState, useEffect } from "react";
import "./Header.css";
import Search from "./Essentials/Search/Search";
import Explore from "./Essentials/Explore/Explore";
import { ApiHitPost } from "../../../Services/ApiHit";
import Loader from '../Loader/Loader';

const Header = ({ SelectedVertical, onVerticalSelect, HandleLogout, LoggedInUserProfilePhoto, LoggedInUserFullName, loading, isLoggedInUser }) => {
  const [toShowUserProfile, setToShowUserProfile] = useState(false);
  const [showLogoutButton, setShowLogoutButton] = useState(false);

  const HandleLoginClick = (Url) => {
    localStorage.setItem('dla_web_student_redirect_back_to_login', window.location.pathname)
    window.location = Url;
  }

  useEffect(() => {
    if (isLoggedInUser) {
      setToShowUserProfile(true);
    } else {
      setToShowUserProfile(false);
    }
  }, [isLoggedInUser])

  const toggleLogoutButton = () => {
    setShowLogoutButton(!showLogoutButton);
  }

  if (loading) {
    return (<Loader />);
  }

  return (
    <div className="header-container">
      <div className="header-left">
        <a href="/" className="home-link">
          <img src='/drishti_favicon.ico' alt="Logo" className="header-logo" />
        </a>
      </div>
      <div className="header-middle">
        <Explore />
        <div className="search-container">
          <Search SelectedVertical={SelectedVertical} onVerticalSelect={onVerticalSelect} />
        </div>
      </div>
      {
        toShowUserProfile ?
          <div className="ProfilePhotoAndNameDiv" onClick={toggleLogoutButton}>
            <img className="ProfilePhoto" src={LoggedInUserProfilePhoto} alt=""></img>
            <span className="ProfileNameAndLogoutDiv">
              <span>{LoggedInUserFullName}</span>
              <br />
              {showLogoutButton && (
                <button onClick={HandleLogout} className="LogoutButton">Logout</button>
              )}
            </span>
          </div>
          :
          <div className="header-right">
            <button onClick={() => HandleLoginClick('/login')} className="login-signup-button">Login</button>
          </div>
      }
    </div>
  );
};

export default Header;
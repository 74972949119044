import React from "react";
import styles from "./BuyNowButton.module.css";

const BuyNowButton = ({ onFullPaymentClick,onEmiPaymentClick,toShowEmiButton }) => {
  return (
    <>
      {
        toShowEmiButton
        &&
        <button 
          className={styles.BuyNowButton} 
          onClick={onEmiPaymentClick}
        >
          Pay With EMI
        </button> 
      }
      <button 
        className={styles.BuyNowButton} 
        onClick={onFullPaymentClick}
      >
        Buy Now
      </button>
    </>
  );
};

export default BuyNowButton;

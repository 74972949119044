// PATH: src/Components/Login/Essentials/UserDetailsForm/UserDetailsForm.js

import React, { useState, useEffect } from 'react';
import { ApiHitPost } from '../../../../Services/ApiHit';
import styles from './UserDetailsForm.module.css';
import Loader from '../../../Includes/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import VerifyOtp from '../VerifyOtp/VerifyOtp';

const UserDetailsForm = ({ userData, isGoogleLogin, setUserData, setStep, handleLogin }) => {
  const [firstName, setFirstName] = useState(userData.first_name || '');
  const [lastName, setLastName] = useState(userData.last_name || '');
  const [mobileNumber, setMobileNumber] = useState(userData.mobile_number || '');
  const [email, setEmail] = useState(userData.email_id || '');
  const [dob, setDob] = useState(userData.dob || '');
  const [state, setState] = useState(userData.state || '');
  const [gender, setGender] = useState(userData.gender || '');
  const [states, setStates] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showOTPVerification, setShowOTPVerification] = useState(false);
  const [SocialSendOtpApiResponse, setSocialSendOtpApiResponse] = useState({});
  
  const navigate = useNavigate();

  useEffect(() => {
    ApiHitPost('/user/get_state_list', {
      country_id: userData.country_id??102
    })
      .then(response => {
        setStates(response.data);
      })
      .catch(error => {
        console.error('Error fetching states:', error);
      });
  }, [userData.country_id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (firstName === '' || lastName === '' || mobileNumber === '' || email === '' || dob === '' || state === '' || gender === '') {
      setError('Please fill out all fields');
      return;
    }
    setError('');
    try {
      const TempToken = localStorage.getItem("dla_web_student_u_temp");
      ApiHitPost('/user/onboarding/update_profile', {
        first_name: firstName,
        last_name: lastName,
        mobile_number: mobileNumber,
        email_id: email,
        dob: dob,
        state: parseInt(state),
        gender: parseInt(gender),
        country_id: userData.country_id,
        is_agreed_terms_and_condtn: 1
      }, {
        'Authorization': `Bearer ${TempToken}`
      }).then(response => {
        setLoading(false);
        if (response.status === 'SUCCESS') {
          if (!userData.is_mobile_verified) {
            handleSendOtp();
          } else {
            localStorage.setItem('dla_web_student_u', localStorage.getItem('dla_web_student_u_temp'));
            localStorage.setItem('dla_web_student_r', localStorage.getItem('dla_web_student_r_temp'));
            localStorage.setItem('dla_web_student_first_name', response.data.first_name);
            localStorage.setItem('dla_web_student_last_name', response.data.last_name);
            localStorage.setItem('dla_web_student_profile_picture', response.data.profile_picture);
            localStorage.setItem('dla_web_student_selected_vertical', response.data.selected_vertical);
            localStorage.setItem('dla_web_student_email_id', response.data.email_id);
            localStorage.setItem('dla_web_student_gender', response.data.gender);
            localStorage.removeItem('dla_web_student_u_temp');
            localStorage.removeItem('dla_web_student_r_temp');
            const dla_web_student_redirect_back_to_login = localStorage.getItem('dla_web_student_redirect_back_to_login');
            handleLogin();
            if(dla_web_student_redirect_back_to_login == null){
              navigate('/');
            }else{
              localStorage.removeItem('dla_web_student_redirect_back_to_login');
              navigate(dla_web_student_redirect_back_to_login);
            }
          }
        } else {
          setLoading(false);
          setError(response.data.message || 'Error updating profile. Please try again.');
        }
      }).catch(error => {
        setLoading(false);
        setError((error.response && error.response.data && error.response.data.message) || 'Error updating profile. Please try again.');
        console.error('Error updating profile:', error);
      });
    } catch (error) {
      setLoading(false);
      setError('Error updating profile');
      console.error('Error updating profile:', error);
    }
  };

  const handleSendOtp = () => {
    setError('');
    setLoading(true);
    const TempToken = localStorage.getItem("dla_web_student_u_temp");
    ApiHitPost('/auth/social/send_otp', {}, {
      'Authorization': `Bearer ${TempToken}`
    }).then(response => {
      setLoading(false);
      if (response.status === 'SUCCESS') {
        setShowOTPVerification(true);
        setSocialSendOtpApiResponse(response.data);
      } else {
        setError(response.message || 'Error sending OTP. Please try again.');
      }
    }).catch(error => {
      setLoading(false);
      setError((error.response && error.response.data && error.response.data.message) || 'Error sending OTP. Please try again.');
      console.error('Error sending OTP:', error);
    });
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && !showOTPVerification && (
        <div className={styles.userDetailsForm}>
          <div className={styles.logo}>
            <img src="https://www.drishtiias.com/drishti/img/drishti_logo_new_eng.png" alt="Drishti Learning App" />
          </div>
          <h1 className={styles.HeadingDLA}>Drishti Learning App</h1>
          <div className={styles.progressBar}>
            <div className={`${styles.step} ${styles.completed}`}>1</div>
            <div className={`${styles.step} ${styles.completed}`}>2</div>
            <div className={`${styles.step} ${styles.active}`}>3</div>
          </div>
          <div className={styles.stepLabel}>
            <span className={`${styles.Step1Text} ${styles.completed}`}>Enter Mobile Number</span>
            <span className={styles.completed}>Get & Verify OTP</span>
            <span className={`${styles.Step3Text} ${styles.active}`}>Provide Information</span>
          </div>
          <br />
          <form className={styles.FormTag} onSubmit={handleSubmit}>
            <div className={styles.nameContainer}>
              <input
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                className={styles.nameInput}
              />
              <input
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                className={styles.nameInput}
              />
            </div>
            <input
              type="text"
              placeholder="Mobile Number"
              value={mobileNumber}
              onChange={e => setMobileNumber(e.target.value)}
              disabled={!isGoogleLogin}
            />
            <input
              type="email"
              placeholder="Email ID"
              value={email}
              onChange={e => setEmail(e.target.value)}
              disabled={isGoogleLogin}
            />
            <input
              type="date"
              placeholder="DOB"
              value={dob}
              onChange={e => setDob(e.target.value)}
            />
            <select value={state} onChange={e => setState(e.target.value)}>
              <option value="">Select State</option>
              {states.map(state => (
                <option key={state.id} value={state.id}>{state.name}</option>
              ))}
            </select>
            <select value={gender} onChange={e => setGender(e.target.value)}>
              <option value="">Select Gender</option>
              <option value="1">Male</option>
              <option value="2">Female</option>
              <option value="3">Other</option>
            </select>
            <label>
              <input type="checkbox" required />
              <span> I agree to <span onClick={() => {window.open("/terms-and-condition")}} className={styles.TermsAndConditionText}>Terms And Condition</span> </span>
            </label>
            {error && <div className={styles.errorMessage}>{error}</div>}
            <button type="submit" className={styles.submitButton}>Submit</button>
          </form>
        </div>
      )}
      {showOTPVerification && (
        <VerifyOtp
          MobileNumber={mobileNumber}
          ResendTime={SocialSendOtpApiResponse?SocialSendOtpApiResponse.retry_in:0}
          SelectedCountryCode={userData.country_id}
          userData={userData}
          setUserData={setUserData}
          setStep={setStep}
          isVerifyOtpAfterSocialLogin={true}
          handleLogin={handleLogin}
        />
      )}
    </>
  );
};

export default UserDetailsForm;

import React from "react";
import styles from "./AddToCartButton.module.css";
const AddToCartButton = ({ isInCart, onAddToCart, onGoToCart }) => {
  return (
    <button
      className={styles.AddToCartButton}
      onClick={isInCart ? onGoToCart : onAddToCart}
    >
      {isInCart ? "Go to Cart" : "Add to Cart"}
    </button>
  );
};

export default AddToCartButton;

import React from 'react';
import styles from './LockOverlayElement.module.css'; // Import your CSS module

const LockOverlayElement = ({ lockMessage,lockEmiButtonToShow, payEmiButtonText, handleAnotherEmiPaymentBuyNow }) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.overlayContent}>
        
        <span className={styles.courseLockedText} >
            Course Locked
        </span>

        <span className={styles.lockMessageText} >
            {lockMessage}
        </span>
        {
            lockEmiButtonToShow
            &&
            <>
                <br />
                <button 
                    className={styles.payEmiButton}
                    onClick={handleAnotherEmiPaymentBuyNow}
                >
                    {payEmiButtonText}
                </button>
            </>
        }
      </div>
    </div>
  );
};

export default LockOverlayElement;

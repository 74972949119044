import React from 'react';
import styles from './MyCourses.module.css';

const coursesData = {
    "courses": [
      {
        "title": "Geography Optional",
        "category": "Books",
        "language": "English",
        "medium": "English",
        "expiresOn": "11/2/2024",
        "purchasedOn": "12/10/23",
        "image": "https://missioneklavyavault.sgp1.digitaloceanspaces.com/course_management/course/thumbnail/1719404012508_Asset%201292%404x.png",
        "progress": {
          "completed": 4,
          "total": 4,
          "steps": ["Completed", "Completed", "Completed", "Completed"]
        }
      },
      {
        "title": "Geography Optional",
        "category": "Books",
        "language": "English",
        "medium": "English",
        "expiresOn": "11/2/2024",
        "purchasedOn": "12/10/23",
        "image": "https://missioneklavyavault.sgp1.digitaloceanspaces.com/course_management/course/thumbnail/1719404012508_Asset%201292%404x.png",
        "progress": {
          "completed": 1,
          "total": 4,
          "steps": ["Completed", "Upcoming", "Pending", "Pending"]
        },
        "actions": [
          { "icon": "📝", "text": "Submit Answer" },
          { "icon": "📢", "text": "Submit Feedback" }
        ]
      }
    ]
  };

const CourseCard = ({ course }) => (
  <div className={styles.courseCard}>
    <div className={styles.bannerSection}>
      <img src={course.image} alt={course.title} className={styles.bannerImage} />
      <div className={styles.bannerContent}>
        <h2>{course.title}</h2>
        <CourseDetails course={course} />
      </div>
    </div>
    
  </div>
);

const CourseDetails = ({ course }) => {
    const allEMIsPaid = course.progress && course.progress.completed === course.progress.total;
  
    return (
      <div className={styles.courseDetails}>
        <div className={styles.detailRow}>
          <span>Medium : {course.medium}</span>
          <span>Expires on : {course.expiresOn}</span>
          {allEMIsPaid ? (
            <span className={styles.paymentStatus}>Payment : Complete</span>
          ) : (
            <span>Payment : Ongoing</span>
          )}
        </div>
        <div className={styles.detailRow}>
          <span>Category : {course.category}</span>
          <span>Purchased on : {course.purchasedOn}</span>
        </div>
        {course.progress && <CourseProgress progress={course.progress} />}
        {course.actions && <CourseActions actions={course.actions} />}
      </div>
    );
  };

  const CourseProgress = ({ progress }) => (
    <div className={styles.courseProgress}>
      <div className={styles.progressText}>
        {progress.completed}/{progress.total} EMI'S PAID
      </div>
      <div className={styles.progressSteps}>
        {progress.steps.map((step, index) => (
          <div key={index} className={`${styles.progressStep} ${styles[step.toLowerCase()]}`}>
            <div className={styles.stepDot}></div>
            <div className={styles.stepLabel}>{step}</div>
          </div>
        ))}
      </div>
    </div>
  );

const CourseActions = ({ actions }) => (
  <div className={styles.actionButtons}>
    {actions.map((action, idx) => (
      <button key={idx} className={styles.actionButton}>
        <span className={styles.actionIcon}>{action.icon}</span>
        {action.text}
      </button>
    ))}
  </div>
);

const MyCourses = () => (
  <div className={styles.courseContainer}>
    {coursesData.courses.map((course, index) => (
      <CourseCard key={index} course={course} />
    ))}
  </div>
);

export default MyCourses;
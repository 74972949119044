import React, { useState, useEffect } from 'react';
import OrderDetails from './OrderDetails/OrderDetails';
import styles from './MyOrders.module.css';
import { ApiHitPost } from "../../Services/ApiHit";
import Loader from '../Includes/Loader/Loader';
import { useParams } from "react-router-dom";

const MyOrders = () => {
  const [activeTab, setActiveTab] = useState('successful');
  const [transactions, setTransactions] = useState({ success: [], failed: [] });
  const [addressesLoading, setAddressesLoading] = useState(true);

  useEffect(() => {
      
     // Check if no logged in user, redirect on login page
     const dla_web_student_u = localStorage.getItem("dla_web_student_u");
     if (dla_web_student_u === null) {
       localStorage.setItem('dla_web_student_redirect_back_to_login',`/my-cart`);
       window.location.href = '/login';
     }

    const fetchUserAddressesData = async () => {
      try {
        const userAddress = await ApiHitPost('/user/get_my_orders');
        setTransactions({
          success: userAddress.data.transaction_data_success,
          failed: userAddress.data.transaction_data_failure
          ,
        });
      } catch (error) {
        console.error('Failed to load orders:', error);
      } finally {
        setAddressesLoading(false);
      }
    };

    fetchUserAddressesData();
  }, []);

  return (
    <div className={styles.ordersComponent}>
      <div className={styles.tabNavigation}>
        <button
          className={`${styles.tabButton} ${activeTab === 'successful' ? styles.active : ''}`}
          onClick={() => setActiveTab('successful')}
        >
          Successful Orders
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'failed' ? styles.active : ''}`}
          onClick={() => setActiveTab('failed')}
        >
          Failed Orders
        </button>
      </div>

      <div className={styles.ordersList}>
        {addressesLoading ? (
          <p>Loading...</p>
        ) : activeTab === 'successful' ? (
          <OrderDetails transactions={transactions.success} status="successful" />
        ) : (
          <OrderDetails transactions={transactions.failed} status="failed" />
        )}
      </div>
    </div>
  );
};

export default MyOrders;

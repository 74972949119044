import React, { useEffect, useRef, useState } from 'react';

const FadeInSection = ({ children, direction }) => {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);

  return (
    <div
      className={`transition-all duration-1000 ${
        isVisible 
          ? 'opacity-100 transform translate-y-0' 
          : `opacity-0 transform ${direction === 'up' ? 'translate-y-10' : '-translate-y-10'}`
      }`}
      ref={domRef}
    >
      {children}
    </div>
  );
};

const ExamSection = () => {
  const categories = [
    { title: 'State PCS', imageUrl: '/images/Dashboard/statepcsicon.png', color: '#E6F0FF' },
    { title: 'UPSC', imageUrl: '/images/Dashboard/upscicon.png', color: '#FFE6E6' },
    { title: 'Drishti LAW', imageUrl: '/images/Dashboard/drishtilawicon.png', color: '#E6FFE8' },
    { title: 'CUET', imageUrl: '/images/Dashboard/cueticon.png', color: '#FFF5E6' },
    { title: 'K-12', imageUrl: '/images/Dashboard/k-12icon.png', color: '#F0E6FF' },
    { title: 'Publication', imageUrl: '/images/Dashboard/publicationicon.png', color: '#FFE6F0' },
  ];

  return (
    <div className="bg-white py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <FadeInSection direction="up">
          <h2 className="text-3xl font-bold text-center mb-2">
            <span className="text-[#4768DC]">Exam</span> Categories
          </h2>
          <p className="text-lg text-gray-600 text-center mb-12">
            Drishti is preparing students for multiple exam categories.<br />
            Scroll down to find the one you are preparing for.
          </p>
        </FadeInSection>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {categories.map((category, index) => (
            <FadeInSection key={index} direction={index % 2 === 0 ? 'up' : 'down'}>
              <div className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
                <div className="p-6 flex flex-col items-center">
                  <div 
                    className="w-16 h-16 rounded-lg flex items-center justify-center mb-4 transition-transform duration-300 ease-in-out hover:scale-110"
                    style={{ backgroundColor: category.color }}
                  >
                    <img src={category.imageUrl} alt={category.title} className="w-16 h-16 object-contain" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2">{category.title}</h3>
                  <p className="text-gray-500 text-sm mb-4">Lorem ipsum</p>
                  <button className="bg-white text-[#4768DC] border border-[#4768DC] rounded-md px-4 py-2 text-sm font-medium hover:bg-[#4768DC] hover:text-white transition-colors duration-300 ease-in-out">
                    View Courses
                  </button>
                </div>
              </div>
            </FadeInSection>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExamSection;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-columns">
        <div className="footer-column">
          <img
            src="https://www.drishtiias.com/drishti/img/drishti_logo_new_eng.png"
            alt="Logo"
            className="logo"
          />
          <h3 className="footer-heading">Drishti Learning App</h3>
        </div>
        <div className="footer-column">
          <h4>About Drishti IAS</h4>
          <ul>
            <li>
              <a href="/about-us">About Us</a>
            </li>
            <li>
              <a href="https://www.drishtiias.com/careers">Careers</a>
            </li>
            <li>
              <a href="https://www.drishtiias.com/classroom-program">Classroom Program</a>
            </li>
            <li>
              <a href="https://www.drishtiias.com/contact">Contact Us</a>
            </li>
            
          </ul>
        </div>
        <div className="footer-column">
          <h4>Support</h4>
          <ul>
          <li>
              <a href="https://www.drishtiias.com/press-releases">Press Release</a>
            </li>
            <li>
              <a href="https://www.drishtiias.com/support-ticket">Help And Support</a>
            </li>
            <li>
              <a href="/terms-and-condition">Terms of Service</a>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Social</h4>
          <ul className="social-icons">
            <li>
              <a href="https://www.facebook.com/drishtieng/">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            </li>
            <li>
              <a href="https://x.com/drishtiias">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/drishtiiasvideos/">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/drishtiiasenglish/">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
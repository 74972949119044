import React from "react";
import "./Footer.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-container text-white py-16" style={{ backgroundColor: '#3e68be' }}>
      <div className="container mx-auto flex flex-col lg:flex-row justify-between items-start px-4 mb-16">
        <div className="lg:w-1/2 mb-8 lg:mb-0">
          <h3 className="text-2xl font-semibold">Subscribe newsletter</h3>
          <p className="text-blue-300 mt-2">
            Enter your email address to join our mailing list and get our latest updates
          </p>
        </div>
        <div className="lg:w-1/2 flex justify-end">
          <div className="flex items-center space-x-2 w-full lg:w-auto">
            <input
              type="email"
              placeholder="Enter your email"
              className="p-3 rounded-l-lg w-full lg:w-80 focus:outline-none text-gray-800"
            />
            <button className="bg-blue-700 hover:bg-blue-800 p-3 rounded-r-lg text-white">
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </div>
        </div>
      </div>
      <hr/><br/>
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8 px-4 md:px-6">
        <div className="space-y-4 col-span-1 md:col-span-2">
          <img
            src="https://www.drishtiias.com/drishti/img/drishti_logo_new_eng.png"
            alt="Drishti Logo"
            className="w-20"
          />
          <h4 className="text-lg font-semibold">Drishti Learning App</h4>
          <p className="text-blue-300">
            Lorem ipsum dolor sit amet, consectetur adipiscing dolor sit amet, consectetur
          </p>
          <p className="text-blue-300">Except National Holidays</p>
          <p className="text-blue-300">
            1800-121-0206 (TOLL FREE) <br />
            Email: support@drishti.in <br />
            524, Ramlal Kapoor Marg, <br />
            Mukherjee Nagar, New Delhi 110009
          </p>
          <div className="flex space-x-6 text-2xl">
            <a href="https://www.facebook.com/drishtieng/" className="hover:text-blue-400">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="https://twitter.com/drishtiias" className="hover:text-blue-400">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="https://www.linkedin.com/in/drishtiiasvideos/" className="hover:text-blue-400">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
        </div>

        <div>
          <h4 className="text-lg font-semibold mb-4">Features</h4>
          <ul className="space-y-2 text-blue-300">
            <li><a href="#" className="hover:text-blue-400">Community</a></li>
            <li><a href="#" className="hover:text-blue-400">Courses</a></li>
          </ul>
          <h4 className="text-lg font-semibold mb-4 mt-8">Examination</h4>
          <ul className="space-y-2 text-blue-300">
            <li><a href="#" className="hover:text-blue-400">CLAT</a></li>
            <li><a href="#" className="hover:text-blue-400">NLU</a></li>
            <li><a href="#" className="hover:text-blue-400">AILET</a></li>
            <li><a href="#" className="hover:text-blue-400">CLAT Pre</a></li>
          </ul>
        </div>

        <div>
          <h4 className="text-lg font-semibold mb-4">Free Mock Test</h4>
          <ul className="space-y-2 text-blue-300">
            <li><a href="#" className="hover:text-blue-400">CLAT Main Mock Test</a></li>
            <li><a href="#" className="hover:text-blue-400">Advanced Mock Test</a></li>
            <li><a href="#" className="hover:text-blue-400">AILET Mock Test</a></li>
            <li><a href="#" className="hover:text-blue-400">CLAT Mock Test</a></li>
          </ul>
          <h4 className="text-lg font-semibold mb-4 mt-8">Tools</h4>
          <ul className="space-y-2 text-blue-300">
            <li><a href="#" className="hover:text-blue-400">Main Prep Tracker</a></li>
            <li><a href="#" className="hover:text-blue-400">Advanced Main Prep Tracker</a></li>
            <li><a href="#" className="hover:text-blue-400">AILET Main Prep Tracker</a></li>
            <li><a href="#" className="hover:text-blue-400">CLAT Main Prep Tracker</a></li>
            <li><a href="#" className="hover:text-blue-400">CLAT Advanced College Predictor</a></li>
          </ul>
        </div>

        <div>
          <h4 className="text-lg font-semibold mb-4">Resources</h4>
          <ul className="space-y-2 text-blue-300">
            <li><a href="#" className="hover:text-blue-400">Blog</a></li>
            <li><a href="#" className="hover:text-blue-400">Webinar</a></li>
            <li><a href="#" className="hover:text-blue-400">Support</a></li>
          </ul>
          <h4 className="text-lg font-semibold mb-4 mt-8">E-Books</h4>
          <ul className="space-y-2 text-blue-300">
            <li><a href="#" className="hover:text-light-400">Ultimate guide to CLAT</a></li>
            <li><a href="#" className="hover:text-blue-400">Tips to crack CLAT by Toppers</a></li>
            <li><a href="#" className="hover:text-blue-400">Top 5 Preparation tips for AILET</a></li>
            <li><a href="#" className="hover:text-blue-400">One Month Mantra to crack AILET 2023</a></li>
          </ul>
        </div>
      </div>

      <div className="container mx-auto mt-12 px-4 md:px-6">
        <h4 className="text-lg font-semibold mb-4">Previous Test Papers</h4>
        <ul className="flex flex-wrap gap-6 text-blue-300">
          <li><a href="#" className="hover:text-blue-400">CLAT Main & Advanced</a></li>
          <li><a href="#" className="hover:text-blue-400">AILET</a></li>
          <li><a href="#" className="hover:text-blue-400">Sample Papers</a></li>
        </ul>
      </div>

      <div className="mt-16 border-t border-light-300 pt-8">
        <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4 md:px-6">
          <p className="text-blue-300">&copy; {currentYear} Drishti IAS. All rights reserved.</p>
          <ul className="flex flex-wrap justify-center md:justify-end gap-4 md:gap-6 text-blue-300 mt-4 md:mt-0">
            <li><a href="#" className="hover:text-blue-400">About Us</a></li>
            <li><a href="#" className="hover:text-blue-400">Methodology</a></li>
            <li><a href="#" className="hover:text-blue-400">Terms of Use</a></li>
            <li><a href="#" className="hover:text-blue-400">Privacy Policy</a></li>
            <li><a href="#" className="hover:text-blue-400">Disclaimer</a></li>
            <li><a href="#" className="hover:text-blue-400">Copyright</a></li>
            <li><a href="#" className="hover:text-blue-400">Contact Us</a></li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

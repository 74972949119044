
import React from 'react';
import styles from './TermsAndCondition.module.css';

const TermsAndCondition = () => {
    
    return (
        <>
            <div className={styles.container}>
                <div className={styles.row}>
                    <div className="col-md-12">
                        <div className={styles.aboutCon}>
                            <h4 className={styles.h4}>Terms of Use</h4>
                                <p className={styles.p}>VDK EDUVENTURES PVT LTD, operating under the name of DRISHTI IAS, is a popular brand that provides guidance to prepare for Civil Services Examination conducted by UPSC (Union Public Services Commission) and various Provincial Civil Services Exams conducted by Public Service Commissions of various states (for example Uttar Pradesh, Madhya Pradesh, Bihar, Rajasthan etc) and intends to expand its services for other educational services including school classes, college classes, other competitive exams such as NET, TET, CET, CLAT conducted by various agencies like NTA, CRA, SSC, RRB etc.</p>
                                <p className={styles.p}>These Terms and Conditions of Use (hereinafter Terms of Use) pertains to the services made available at http://www.drishtiias.com (hereinafter "Drishti IAS Website") and its equivalent Drishti Learning Mobile Application (hereinafter "Drishti Learning App") available on Google Play, Windows Store or Apple Store. The Drishti Learning App and Drishti IAS Website, on which the services are availed, will be hereinafter collectively referred to as Platform. We request you to read the Terms of Use thoroughly, before using the Platform as it governs your use of the Platform and any other applications, products, websites and services owned and managed by the Company.</p>
                                <p className={styles.p}>For the purpose of this Terms of Use, wherever the context so requires Your, You, Yourself or User shall mean any natural or legal person who browses the Platform or has agreed to become a Subscriber to the services available on the Platform, by providing login credentials while registering on our Platform as a Subscribed User (defined in Clause 2.1) using the Computer/Mobile/Tablet or/and other devices or systems.</p>
                                <p className={styles.p}>
                                    The term We, Us, Our shall mean VDK Eduventures Pvt Ltd the Company.
                                </p>
                                <p className={styles.p}>We reserve the right to amend the Terms of Use at any time, which shall be updated on this page, regularly, so that you are always aware of the latest developments in the Platform. In case of any changes or updates, we shall endeavour to notify You of such changes at the earliest. Nevertheless, please ensure that You read our Terms of Use and any updated version thereof before proceeding to use the Platform or the Services, as the case maybe. The Terms of Use is subject to be amended without prior notice and it is your duty to be apprised of the same.
                                </p>
                                <p className={styles.p}>This Terms of Use applies solely to Our Platform. We state that this Terms of Use is not applicable to other such similar or related Platform and the Terms of Use of other Platforms and websites may vary, accordingly we are not responsible for the Terms of Use of other such Platforms.
                                </p>
                                <p className={styles.p}>Please be informed that as a user you are using our services and hence, trust us. Therefore, it becomes pertinent that we are able to provide you with the best quality services with minimal effort. We endeavour to ensure that every user experience is smooth and hassle-free. These Terms and Conditions of Use, guide user behaviour and in the process lay down certain parameters and guidelines, which would be beneficial to both, you and us, the Company.</p>
                                <h3 className={styles.h3}>1. Acceptance of Terms</h3>
                                <p className={styles.p}>
                                    1. These Terms of Use set forth a legally binding contract between Us, and You and by using the Platform, you agree to the Terms of Use stated herein. The Terms of Use equally apply, even if You only visit the Platform or review any of the service offerings made available by Us and are bound by these Terms of Use. If you do not agree to these Terms of Use, please do not continue to access and use our Platform, henceforth.
                                </p>
                                <p className={styles.p}>
                                    <ul className={styles.ul}>
                                        <li className={styles.li}>
                                            2. In consideration of your use of the Drishti IAS Website and Drishti Learning App, You agree.
                                        </li>
                                        <li className={styles.MarginLeft}>
                                            <br/>
                                            (a) To provide true, accurate, current and complete information about yourself complying with Clause 2 of this Terms of Use and as prompted in order to generate the login credentials.
                                        </li>
                                        <br/>
                                        <li className={styles.MarginLeft}>(b) To the responsibility of ensuring that You meet the required qualiﬁcation while opting for the courses subscribed.</li>
                                        <br/>
                                        <li className={styles.MarginLeft}>(c) To maintain and promptly update your login credentials to keep it true, accurate, current and complete</li>
                                        <br/>
                                        <li className={styles.MarginLeft}>(d) And acknowledge that Drishti IAS is not responsible to ensure that You conform to the eligibility criteria</li>
                                        <br/>
                                        <li className={styles.MarginLeft}>(e) To seek the consent of Your parents/legal guardians, in case you are a minor, before providing any information about Yourself or Your parents and other family members, on the Drishti Learning App</li>
                                        <br/>
                                        <li className={styles.MarginLeft}>(f) That We are not responsible or liable for any consequences, that arise as a result of misuse of any kind, by You, in the Drishti Learning App, Drishti IAS Website or any of Our services, such that it causes any harm or injury to a third-party.</li>
                                        <br/>
                                        <li className={styles.MarginLeft}>(g) That We reserve the right to change and update the content in the Platform to represent the latest evidence and practices, with the changing times, needs and requirements. We reserve the right to constantly review, update and replace the content and features of all the Study Materials, including but not limited to Test Series, Videos and Notes, which would be carried out on a yearly basis in the form of ‘Editions’. We do not guarantee that any contentor features, of the previous Edition, would be made available on the Platform in the new Edition.</li>
                                        <br/>
                                        <li className={styles.MarginLeft}>(h) That We reserve all right to engage a different faculty or tutor or change the current faculty or tutor at any given point of time without any prior notification to the User.</li>
                                        <br/>
                                    </ul>
                                </p>
                        <h3 className={styles.h3}>2. Eligibility</h3>
                        <p className={styles.p}>
                        <ul className={styles.ul}>
                            <li className={styles.li}>
                                1. Persons who are "competent/capable" of contracting within the meaning of the Indian Contract Act, 1872 shall be eligible to register for Drishti Learning App and be a Registered User (defined hereunder). As a minor if You wish to use the Drishti Learning App or Our Services, such use shall be made available to You by Your legal guardian or parents, who has agreed to these Terms. In the event a minor utilizes the Drishti Learning App/ Drishti IAS Website/Services, it is assumed that he/she has obtained the consent of the legal guardian or parents and such use is made available by the legal guardian or parents
                            </li>
                            <br/>
                            <li className={styles.li}>
                                2. You are mandatorily required to either be a person of competence, complying with Clause 2.1, as mentioned above.
                            </li>
                            <br/>
                            <li className={styles.li}>
                                3. To register on the Platform for availing the Services (as deﬁned in Clause 3.1) a login account, which is based on a paid subscription, is required. You become a Subscriber/Subscribed User (hereinafter ‘Subscriber’) once the account is created and an appropriate plan is chosen with the correct payment being done for the plan.
                            </li>
                            <br/>
                            <li className={styles.li}>
                                4. To determine Your eligibility as a Subscriber, You will be required to give details to validate Your eligibility by ﬁlling in all mandatory ﬁelds in the Registration Form (or Online Service Subscription Enrolment Form) on Platform. Upon completing all ﬁelds in the Registration Form, You will have access to the ‘Free Content’ or the ‘Basic Plan’ in the capacity of a ‘Registered User’ (hereinafter Registered User) and have the option to purchase a paid subscription of your choice.
                            </li>
                            <br/>
                            <li className={styles.li}>
                                5. If You choose to purchase a paid subscription, You will be directed to the purchase page and have the option to select the services and duration for which You wish to become a Subscribed User. Upon receipt of the payment in full (or in Part, if such choice is given by the US), the user would become a “Subscribed User”. After the payment is made, You will receive a conﬁrmation Email/SMS to the registered Email ID/Mobile Number conﬁrming your plan details and credentials for accessing the Platform.
                            </li>
                            <br/>
                            <li className={styles.li}>
                                6. Post receipt of the payment in full, the user shall normally be required to submit their one valid identity proof issued by the Government of India i.e. Aadhaar Card, Passport, Voter’s ID, MCI card etc, as acceptable by the Us. Upon submission of the valid identity proof, the Platform shall normally conduct a verification process of each such user. In such cases where the user has been asked to submit an identity proof, only on successful completion of the verification process by Us, You will attain the status of a Subscribed User.
                            </li>
                        </ul>
                        </p>
                        <h3 className={styles.h3}>3. Services of Drishti IAS</h3>
                        <p className={styles.p}>
                        <ul className={styles.ul}>
                            <li className={styles.li}>1. To avail the full range of course content offered as part of the Services, You will have to mandatorily be a Subscribed User meeting all the requirements set out in Clauses 2.2 and 2.3.</li>
                            <br/>
                            <li className={styles.li}>2. The Services and amounts payable to access the Services may differ for different Subscribers and will be based on the course combination chosen by You either at the time of enrolling as a Subscribed User or thereafter, based on the courses a Subscribed User adds to his/ her account.</li>
                            <br/>
                            <li className={styles.li}>3. In these Terms of Use, the term Services (hereinafter referred to as ‘services’) shall mean and include without limitation, the online tutorial videos and content as well as study materials that will be accessible to You as a Subscribed User and will exclude the free demo content that is accessible to all Registered Users visiting the Platform. The foregoing meaning and scope of Services may be subject to change and the deﬁnition shall not in any way limit or restrict Our right to remove or add features to the Platform.</li>
                            <br/>
                            <li className={styles.li}>4. As a “Registered User”, You are enrolled to the free plan by default and have the option to purchase a course and become a Subscribed User.</li>
                            <br/>
                            <li className={styles.li}>5. You agree that any Services provided by Us may be subject to change at Our discretion and We may add, remove or modify offerings to its existing scope of Services at such fees as will be solely determined by Us.</li>
                            <br/>
                            <li className={styles.li}>6. We may, at Our discretion, revise the amount payable in respect of existing offerings forming part of the Services that may be made available to You.</li>
                            <br/>
                            <li className={styles.li}>7. You agree that We may at any time and for any reason, terminate Your access to whole or part of the Platform, or restrict or suspend Your access to your Subscribed User account, for any or no reason, with or without prior notice, and without any liability to You.</li>
                        </ul>
                        </p>
                        <h3 className={styles.h3}>4. Subscriber Account, Password and Security</h3>
                        <p className={styles.p}>If You use the Platform and wish to avail Services by creating a User account, You may be required to submit your phone number and/or email ID and choose a password which will be the only set of credentials, on the basis of which You will be granted access to the Platform and its Services. You are responsible for maintaining the confidentiality of Your password and other credentials to access the Services. If there is any compromise to Your account, You can change your password using the ‘Forgot Password’ functionality available on the login page used to access the Platform.</p>
                        <h3 className={styles.h3}>5. Third Parties</h3>
                        <p className={styles.p}>We may use/integrate another third party’s platform on Our Website or on Drishti Learning App. We may also use third party platforms to provide services to ourselves. In such event, You will be bound by such third party’s terms and conditions, privacy policy, and any other applicable policies. You also agree and hereby authorize Drishti IAS to share Your details and personal information with such third parties to the extent necessary for Drishti IAS to deliver the Services to You.</p>
                        <h3 className={styles.h3}>6. Payment Terms</h3>
                        <p className={styles.p}>
                            1. Any payments made to Drishti IAS in respect of Services are subject to the payment terms of Drishti IAS as will be notified to You once You initiate the process to become a Subscribed User.
                            <br/><br/>
                            2. The Fee including GST and any delivery charges payable in relation to delivery of electronic/physical versions of document based Study materials, if applicable, will be shown prior to completion of the online payment transaction.
                            <br/><br/>
                            3. In the unlikely event that, due to a technical error, the amount of the Fee displayed on the Platform is incorrect, Drishti IAS will notify You as soon as it reasonably can. If the correct amount of the Fee is higher than the amount displayed on the Platform, Drishti IAS will contact You through your registered e-mail address and/or phone number to notify You of the correct Fee. To avail all Services for the duration of Subscription, You may be required to remit any additional fees so that the correct Fee is paid by you.
                            <br/><br/>
                            4. Drishti IAS hereby notifies You that Drishti IAS uses third party payment gateway service providers to process payment made by You towards the Service subscription. It is hereby clarified that Drishti IAS will not be responsible for any payment failures or errors occurring due to technical issues at the bank’s end or issues arising from the third party’s payment gateway and all such issues should be resolved directly between You and the banking or payment gateway partner concerned.
                            <br/><br/>
                            5. The provision of the online tutorials forming part of the Services are contingent upon Drishti IAS having received cleared funds from You in respect of the Fee for the relevant Service subscription. Without prejudice to Drishti IAS’s rights and remedies under these Terms of Use, if any sum payable is not paid on or before the due date, Drishti IAS reserves the right, forthwith and at Drishti IAS’s sole discretion, to suspend Your access to the Services and refuse You entry to the course You intended to avail as part of the subscription to the Platform.
                        </p>
                        <h3 className={styles.h3}>7. Disclaimer as regards Study materials</h3>
                        <p className={styles.p}>
                            1. The term ‘Study Materials’ (hereinafter Study Materials) as used in these Terms of Use include the videos, Test Series and any other learning material posted for the speciﬁc topics as well as other electronic materials that may be made available from time to time. At times, soft copy document versions of the video lectures may be provided to a Subscribed User and is based on Our sole assessment as regards the need for such material. Where soft copy Study Materials accompany the services, these Study Materials will be made available to You, upon meeting all the conditions stipulated in these Terms of Use and becoming a Subscribed User.
                            <br/><br/>
                            2. We do not make any representation, guarantee or commitment to You that the Study Materials offered either in the demo versions or as part of subscribed services will be error free.
                            <br/><br/>
                            3. The MCQs provided in the Study Materials is inclusive of all kind of MCQs provided in the Study Material i.e., via test series, notes etc. The content will be constantly updated (addition or removal) in terms of its numbers and the material without any prior notice to You and at a sole discretion of Drishti IAS.
                            <br/><br/>
                            4. We do not claim any guaranteed rank, mark, success/any kind of professional advantage with the use of the Platform.
                            <br/><br/>
                            5. We reserve the right to change and update the content in the Platform to represent the latest evidence and practices, with the changing times, needs and requirements. We reserve the right to constantly review, update and replace the content and features of all the Study Materials, including but not limited to Test Series, Videos and Notes, which would be carried out on a yearly/half-yearly/monthly basis in the form of ‘Editions’. We do not guarantee that any content or features, of the previous Edition, would be made available on the Platform in the latest Edition.
                            <br/><br/>
                            6. We reserve all right to engage a different faculty or tutor or change the current faculty or tutor at any given point of time without any prior notification to the User.
                        </p>
                        <h3 className={styles.h3}>8. Online Subscription and Access Terms</h3>
                        <p className={styles.p}>
                            1. Except as set out in the description of the subscription model available on the Platform, no additional study materials and/or video tutorials will be provided by Us.
                            <br/><br/>
                            2. Upon receipt of a conﬁrmation e-mail from Us you will be notiﬁed when You (only if you are a Subscribed User) have access to purchased services and for the length of time such access will be made available to You, subject however to Our absolute right to suspend or terminate access in accordance with these Terms of Use.
                            <br/><br/>
                            3. A subscription received is personal to You and You shall not transfer or share your right to access the Study Material or further sell the subscription or allow access to the subscription to any other person for consideration or otherwise. Such acts will be construed as contrary to this Terms of Use and in such a situation we reserve the right to suspend or terminate access to Your account, with or without prior notice.
                        </p>
                        <h3 className={styles.h3}>9. System Requirements</h3>
                        <p className={styles.p}>
                            1.    Please note that it is Your responsibility to check that the Mobile Phone, Tablet, Computer or any other device You plan to use to access the subscription is compatible with the minimum speciﬁcation requirement that relates to the subscription You are opting for. You acknowledge and accept that We cannot be held responsible for any technical problems (including but not limited to playback of video content) with any systems, computers or devices You encounter following the commencement of the subscription.
                            <br/><br/>
                            2.    Please find below the Minimum System Requirements for using our Platform:
                            <br/>
                            <font className={styles.MarginLeft}>(a)   Android : 6.0 (Lollipop) & above</font>
                            <br/>
                            <font className={styles.MarginLeft}>(b)   iOS 8  & Above</font>
                            <br/><br/>
                            3. We recommend the Latest version of Chrome or Firefox.
                            <br/><br/>
                            4. Please note that the above mentioned has been derived based on User experience and is not conclusive or a guarantee that if the above-mentioned criteria is fulfilled, the Platform would work in the like manner. Other unforeseeable circumstances too must be considered while using the services on the Platform.
                        </p>
                        <h3 className={styles.h3}>10. Modifications to Subscription</h3>
                        <p className={styles.p}>
                            1. We reserve the right to make modiﬁcations, enhancements or issue clariﬁcations (for example, clariﬁcation of doubt, interactive sessions) to the subscription, including but not limited to changes in Services, number of Services available, pricing and validity of Subscription duration.
                            <br/><br/>
                            2. When we make such changes, We may or may not notify the User of the same and the User will be bound by such modifications, enhancement, changes and amendments, as the case may be.
                            <br/><br/>
                            3. These changes would be called as ‘Editions’ and we have complete discretion to update the same with time, as We deem fit and cannot be challenged by You at any time.
                            <br/><br/>
                            4. We do not guarantee that the contents or features of the previous versions/Editions would be made available post the launch of the new edition.
                        </p>
                        <h3 className={styles.h3}>11. Technical Support and Access</h3>
                        <p className={styles.p}>
                            1. We do not warrant uninterrupted or error-free operation of the subscription.
                            <br/><br/>
                            2. We are not obliged to offer You any technical support in relation to your subscription other than the speciﬁc support that has been included as part of the subscription plan You have opted for.
                            <br/><br/>
                            3. You also accept and acknowledge that We cannot be held responsible for any delay or disruptions to your subscription as a result of such suspension or any of the following but not limited to:
                            <br/>
                            <font className={styles.MarginLeft}>(a) The operation of the internet and the World Wide Web, including but not limited to viruses</font>
                            <br/>
                            <font className={styles.MarginLeft}>(b) Any firewall restrictions that have been placed on your network or the computer You are using to access the Services</font>
                            <br/>
                            <font className={styles.MarginLeft}>(c) Failures of telecommunications links and equipment; or</font>
                            <br/>
                            <font className={styles.MarginLeft}>(d) Updated browser issues</font>
                            <br/>
                            <font className={styles.MarginLeft}>(e) DRM protocols preventing playback of media</font>
                            <br/>
                            <font className={styles.MarginLeft}>(f) Not meeting standards any of the minimum system requirements as defined in clause 9.</font>
                        </p>
                        <h3 className={styles.h3}>12. Disclaimer of Warranties and Liabilities</h3>
                        <p className={styles.p}>
                            1. We shall not be liable for any interference, virus, hacking, or any such consequence, caused by any network or internet service providers and failure of device manufacturers to support DRM protocols.
                            <br/><br/>
                            2. Any content posted on a third-party platform, by Us or any authorised third party shall be subject to the terms and conditions, and policies, of that third-party platform in addition to the terms and conditions set forth herein.
                            <br/><br/>
                            3. SUBJECT TO APPLICABLE LAWS, IN NO EVENT WILL DRISHTI IAS OR ITS EMPLOYEES’, OR ITS AGENTS’, PARTNERS, AND CONTRACTORS’, AGGREGATE LIABILITY ARISING FROM OR RELATED TO THE AFORESAID SERVICES TO YOU, EXCEED THE PAYMENTS ACTUALLY RECEIVED AND RETAINED BY DRISHTI IAS FROM YOU, FOR ANY AND ALL CAUSES OF ACTION BROUGHT BY YOU OR YOUR AGENTS. IN THE EVENT THAT YOU ARE NOT A SUBSCRIBED USER, DRISHTI IAS WILL NOT BE LIABLE TO YOU UNDER ANY CIRCUMSTANCES.
                        </p>
                        <h3 className={styles.h3}>13. Disclaimer of Services</h3>
                        <p className={styles.p}>
                            You acknowledge and agree that the suggestions and recommendation provided by Us on the Platform, are only suggestions, and You may comply with it at Your choice and option. We offer You various services via the Platform, to help You prepare for Civil Services Examination conducted by UPSC and/or various Provincial Civil Services Exams conducted by Public Service Commissions of various states (for example Uttar Pradesh, Madhya Pradesh, Bihar, Rajasthan etc) and intend to expand our     services for other educational services including school classes, college classes, other competitive exams such as NET, TET, CET, CLAT conducted by various agencies like NTA, CRA, SSC, RRB etc.with customised support and assistance.
                        </p>
                        <h3 className={styles.h3}>14. Data protection</h3>
                        <p className={styles.p}>
                            1. We will process the information that We receive from You or otherwise hold about You in accordance with these Terms of Use and the Privacy Policy. You consent to the use by Us of such information in accordance with these Terms of Use and Drishti IAS’s Privacy Policy.
                            <br/><br/>
                            2. You acknowledge that We may conduct online surveys from time to time. The data collected through these surveys are used to gauge Our services, collect demographic information and other information that We may ﬁnd useful. We may share non-personal, aggregated information with third parties. By agreeing to these Terms of Use, You agree to Us using Your information in this manner and in the manner mentioned in our Privacy Policy.
                        </p>
                        <h3 className={styles.h3}>15. Drishti IAS No Sharing Fair Usage Policy</h3>
                        <p className={styles.p}>
                            1. A Drishti IAS account is meant to be used by only one User.
                            <br/><br/>
                            2. Sharing the Drishti IAS account credentials for both personal use or commercial purposes will result in account blockage without prior notice or warning.
                            <br/><br/>
                            3. As per this Fair Usage policy, each user will have a ﬁxed number of Fair Usage hours which is defined course wise. In case the limit is exceeded, the account will fall under scrutiny and we will immediately investigate the matter further, without Your consent.
                            <br/><br/>
                            4. Apart from the Fair Usage Hours, we also track a number of other parameters to identify any form of sharing.
                            <br/><br/>
                            5. If a violation of this policy is identiﬁed, the same would amount to the substantial breach of the said terms of use and the Company would have all the rights to take any legal action as provided under the law of the land, and also including but not limited to termination/blocking your account permanently without any prior notice to you.
                        </p>
                        <h3 className={styles.h3}>16. Prohibited Conduct</h3>
                        <p className={styles.p}>
                            1. You agree that You shall not use the services or the Platform in order to host, display, upload, modify, publish, transmit, update, distribute, share, store material. You are bound not to cut, copy, decompile, distribute, modify, recreate, reverse engineer, distribute, disseminate post, publish or create derivative works from, transfer, or sell any information or software obtained from the Platform. With Our prior permission, limited use may be allowed and this is solely based on Our discretion. For the removal of doubt, it is clariﬁed that unlimited or wholesale reproduction, copying of the content for commercial or non-commercial purposes and unwarranted modiﬁcation of data and information within the content of the Platform is not permitted and is considered:
                            <br/>
                            <font className={styles.MarginLeft}>(a)   in violation of any applicable law or regulation;</font>
                            <br/>
                            <font className={styles.MarginLeft}>(b)   in a manner that will infringe the copyright, trademark, trade secret or other intellectual property or proprietary rights of others or violate the privacy, publicity or other personal rights of others;</font>
                            <br/>
                            <font className={styles.MarginLeft}>(c)    that belongs to another person and to which the user does not have any right to;</font>
                            <br/>
                            <font className={styles.MarginLeft}>(d)   that is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another’s privacy, threatening, abusive or hateful or racially/ethnically objectionable, disparaging, relating encouraging money laundering or gambling or otherwise unlawful in any manner whatsoever;</font>
                            <br/>
                            <font className={styles.MarginLeft}>(e)   that harms minors in any way;</font>
                            <br/>
                            <font className={styles.MarginLeft}>(f)     that impersonate another person or entity;</font>
                            <br/>
                            <font className={styles.MarginLeft}>(g)   that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of Drishti IAS’s computer/IT systems or site or Drishti IAS’s Users, customer’s computer/IT systems or site;</font>
                            <br/>
                            <font className={styles.MarginLeft}>(h)   threatens the unity, integrity, defence, security or sovereignty of India and any other countries, friendly relations with foreign states or of public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or insulting any other nation.</font>
                            <br/><br/>
                            2.    If You become aware of misuse of the Service by any person, please contact +91-8130392355 immediately.
                            <br/><br/>
                            3.    You shall not either as a regular browsing user, Registered User, Basic Plan user or as a Subscribed User, use the Platform to either directly or indirectly, either alone or with another third party disassemble, reverse engineer or decompile any part or whole of the Platform in order to get access or attempt to get access to the underlying software, source or proprietary technology which enables the Services or other functionalities of the Platform or do anything with an intent to create derivative works or competing platforms that provide same or similar services.
                            <br/><br/>
                            4.    You shall not either directly or through other third parties take, post, publish, transmit or otherwise make available any of the Study Material or video tutorials on any other medium. Furthermore, You undertake not to use, display, mirror or frame the Platform or any individual element within the Platform, unless this is expressly approved by the Us in writing.
                            <br/><br/>
                            5.    You shall not copy or record or make any attempt to copy or record part or whole of the Services or use the Services for further distribution any mode so as to commercially beneﬁt from the Study Material or engage in any form of piracy.
                            <br/><br/>
                            6.    Any violation of the said clause would amount to substantial breach of the said Terms of Use and the Company shall have all the rights to take any legal action as provided under the law of the land.
                        </p>
                        <h3 className={styles.h3}>17. Termination or Suspension of Account for Illegalities or suspected Illegalities</h3>
                        <p className={styles.p}>
                            1. If there is a suspicion of untoward or illegal activity, We may suspend your account immediately and if required, debar all future access by You to the Platform.
                            <br/><br/>
                            2.    We reserve the right to cancel any subscription as We believe has been compromised, or is being used fraudulently, at its own discretion without any ﬁnancial liability to You.
                            <br/><br/>
                            3.    User’s account will be Blocked for the following reasons (including but not limited to):
                            <br/><br/>
                            <font className={styles.MarginLeft}>(a)   If found suspicious,</font>
                            <br/>
                            <font className={styles.MarginLeft}>(b)   Usage of Rooted/ Jailbroken Devices, Emulators, ChromeCast, Android TV, Amazon Fire, Samsung Dex, Cloned APK's 3)TV/ Smart TV/ Apple TV or any other virtual machines.</font>
                            <br/>
                            <font className={styles.MarginLeft}>(c)    Screen Casting, Screen Recording, Screen Mirroring, Downloading Videos out of Drishti IAS Platform,</font>
                            <br/>
                            <font className={styles.MarginLeft}>(d)   Using a camera, phone or any other device to record any Platform content</font>
                            <br/>
                            <font className={styles.MarginLeft}>(e)   Sharing Drishti IAS Screen Shots or PDF's in Social Media or any other Network</font>
                            <br/>
                            <font className={styles.MarginLeft}>(f)     Sharing Drishti IAS Accounts Credentials with anyone.</font>
                            <br/>
                            <font className={styles.MarginLeft}>(g)   Usage of Multiple Devices (Other than the permitted Device/s for Personal Use)</font>
                            <br/>
                            <font className={styles.MarginLeft}>(h)   Abusing Drishti IAS and Drishti IAS Faculties.</font>
                            <br/>
                            <font className={styles.MarginLeft}>(i)     Try to sell Drishti IAS Accounts and Contents on Drishti IAS.</font>
                        </p>
                        <h3 className={styles.h3}>18. Preservation/Disclosure</h3>
                        <p className={styles.p}>
                            You acknowledge, consent and agree that Drishti IAS may access, preserve and disclose Your account information if required to do so by law or in a good faith belief that such access, preservation or disclosure is reasonably necessary to:
                            <br/>
                            <font className={styles.MarginLeft}>(a)   comply with legal process nationally or internationally;</font>
                            <br/>
                            <font className={styles.MarginLeft}>(b)   enforce this Agreement;</font>
                            <br/>
                            <font className={styles.MarginLeft}>(c)   respond to claims that any content violates the rights of third parties;</font>
                            <br/>
                            <font className={styles.MarginLeft}>(d)   protect the rights, property or personal safety of Drishti IAS, its subscribers and the public; or</font>
                            <br/>
                            <font className={styles.MarginLeft}>(e)   pursuant to the terms of the Privacy Policy, reach You for marketing or promotional purposes through any channel.</font>
                        </p>
                        <h3 className={styles.h3}>19. Security Components</h3>
                        <p className={styles.p}>
                            You understand that Drishti IAS Platform and the softwares embodied within Drishti IAS Platform and the Services you access may include security components that permit digital materials including the Study Materials to be protected, and that use of these Study Materials is subject to usage rules set by Drishti IAS. You shall not attempt to override, disable, circumvent or otherwise interfere with any such security components, encryptions and usage rules embedded into Website or the specific Services accessible through Your Service account.
                        </p>
                        <h3 className={styles.h3}>20. Proprietary Rights</h3>
                        <p className={styles.p}>
                            1.  All materials on Website, including, without limitation, names, logos, trademarks, images, text, columns, graphics, videos, photographs, illustrations, artwork, software, notes (soft copies) and any and all other elements (collectively referred to as Material) are protected by copyrights, trademarks and/or other intellectual property rights owned and controlled by Us.
                            <br/><br/>
                            2.  You acknowledge and agree that all Material on the Platform is made available for limited, non-commercial, personal use only. Except as speciﬁcally provided herein or elsewhere on the Platform, no Material may be copied, reproduced, republished, sold, downloaded, posted, transmitted, or distributed in any way, or otherwise used for any purpose, by any person or entity, without Our prior express written permission, which is at Our sole discretion.
                            <br/><br/>
                            3.  You may not add, delete, distort, or otherwise modify the Material. Any unauthorized attempt to modify any Material, to defeat or circumvent any security features, or to utilize the Platform or any part of the Material for any purpose other than its intended purposes is strictly prohibited and such actions will be considered as violation of the Terms of Use and will result in immediate account blockage or any such action the We deem fit.
                        </p>
                        <h3 className={styles.h3}>21. Drishti IAS and Third Parties</h3>
                        <p className={styles.p}>
                            1. All the tutors in the tutorial videos You access as part of the Services You use or subscribe for are independent third parties and not employees of the Company. The professional and technical information contained in the video tutorial are the sole responsibility of the Tutor. Even though We review the content hosted as part of the Services on a best effort basis, We disclaim all warranties as regards the authenticity or correctness of information communicated to You by the tutors and We do not guarantee that the tutor videos contain updated information on the subject matter.
                            <br/><br/>
                            2. The Platform may contain links to other apps or web pages owned by third parties (i.e. advertisers, afﬁliate partners, strategic partners, or others). We are not responsible for examining or evaluating, and we do not warrant the products or offerings of, any of these businesses or individuals, or the accuracy of the content of their website. We do not assume any responsibility or liability for the actions, product and content of any such websites. Before You access or visit any third-party app or website, You should review website terms of use and policies for such app or web pages. If You decide to access any such third-party platform, You do so at your own risk. The hosting of such links to third party content cannot under any circumstances be construed as Our endorsement of such third parties and You shall not implead Us in any suit or claim involving You and such third parties.
                        </p>
                        <h3 className={styles.h3}>22. Intellectual Property Rights</h3>
                        <p className={styles.p}>
                            1.    We provide You with a single limited license to use and access the Platform and Materials hosted as part of the subscribed Services on devices which We have permitted access at the time of Your subscription to the Services for the limited purpose of accessing the tutorial or associated Study Materials online. The license is speciﬁcally personal, non-transferable, and non-exclusive. All content on the Platform, which is including, but not limited to, designs, text, graphics, images, information, logos, button icons, software, audio ﬁles and any other similar content are the exclusive and sole property of Us, the Company.
                            <br/><br/>
                            2.    All icons and logos are trademarks of and proprietary to Us. The unauthorized copying, modiﬁcation, use or publication of these marks is strictly prohibited.
                            <br/><br/>
                            3.    All material on the Platform, including images, illustrations, audio clips, video clips and third-party licensed images are protected by copyrights, trademarks and other intellectual property rights. Material on the Platform is solely for your personal, non-commercial use. You must not copy, reproduce, republish, upload, post, transmit or distribute such material in any way, including by e-mail or other electronic means, whether directly or indirectly and You must not assist any other person to do so.
                            <br/><br/>
                            4.    Without the prior written consent of Us, which is at Our sole discretion, modiﬁcation of the Content, use of the Content on any other website, app or networked computer environment or use of the materials for any purpose other than personal, non-commercial use is a violation of the copyrights, trademarks and other proprietary rights, and is prohibited.
                            <br/><br/>
                            5.    At all times, We and/or Our licensors, remain the owner of the intellectual property in the tutorial, and the study materials. No Course and/or study materials or any part thereof may be reproduced, stored in a retrieval system or transmitted any form or by any means without the prior written permission of Us.
                        </p>
                        <h3 className={styles.h3}>23. Terms and Termination</h3>
                        <p className={styles.p}>
                            1.    This Agreement shall remain in full force and effect for so long as You use the Platform. You may terminate your subscription at any time, for any reason, by contacting the Technical Support Team of the Platform. We, however, do not offer any refunds because of Your termination or discontinuation of the Services.
                            <br/><br/>
                            2.    We reserve the right to terminate Your services without prior notice. Your account or Your access to the Platform maybe terminated immediately, with or without notice to You, and without liability to You, if We believe that You have breached any of covenants, conditions, restrictions or limitations contained in these Terms of Use or the Privacy Policy, or any false or misleading information, or interfered with use of the Platform by others.
                            <br/><br/>
                            3.    All Content on the Platform (including but not limited to software) and Services, included on or otherwise made available to You across all mediums are provided on permitted number of views basis on a single registered device (or a limited number of whitelisted devices) without any representation or warranties, express or implied except otherwise speciﬁed in writing. Without prejudice to the forgoing paragraph, Drishti IAS does not warrant that:
                            <br/>
                            <font className={styles.MarginLeft}>(a)   Platform will be constantly available or;</font>
                            <br/>
                            <font className={styles.MarginLeft}>(b)   The information made available through the Services on the Platform is complete, true or accurate.</font>
                            <br/><br/>
                            4.    We are not responsible to You for any data that You lose either (a) as a result of accessing the tutorial, or (b) as a result of accessing study materials, or (c) otherwise during the course of subscription. It is your responsibility to ensure that You regularly save and back up (i) all data which You hold on the Mobile Phone/ Tablet/ Computer/ Device from which You are accessing the Services and (ii) all data that You are inputting when completing a study module being offered.
                            <br/><br/>
                            5.    We will not be held responsible for any delay or failure to comply with its obligations under these Terms if the delay or failure arises from any causes which is beyond Our reasonable control.
                            <br/><br/>
                            6.    Each provision in these Terms of Use shall be construed separately as between You and Us. If any part of these Terms of Use is held to be unreasonable, inapplicable, or unenforceable, but would be valid if some part thereof was deleted, such provision shall apply but with such modiﬁcation as may be necessary to make it valid and effective.
                            <br/><br/>
                            7.    The tutorials are for personal training and for knowledge enhancement purposes only. We do not accept any responsibility to any party for the use of the Services provided and/or the contents of the Study materials for any purpose other than training for educational purposes, including but not limited to the giving of advice by You to any third party.
                            <br/><br/>
                            8.    We shall not be responsible for any claims that may arise as a direct or indirect consequence of any third-party placing reliance and acting upon the information obtained as part of the Services or Your advice to a third party basis the Study Material or tutorial videos or any other Services.
                        </p>
                        <h3 className={styles.h3}>24. Exclusions and Limitations</h3>
                        <p className={styles.p}>
                            The Platform and Services are intended to be used within the jurisdiction of India only. Those who access or use the Platform from other jurisdictions do so at their own volition and are responsible for compliance with the local laws. Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, in certain jurisdictions, some of the above limitations of liability may not apply to You, nevertheless, all other provisions of these Terms remain in full force and effect.
                        </p>
                        <h3 className={styles.h3}>25. Indemnity</h3>
                        <p className={styles.p}>
                            You agree to defend, indemnify and hold Us harmless, Our subsidiaries, afﬁliates, subcontractors, ofﬁcers, directors, employees, consultants, representatives and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or expenses (including but not limited to attorneys’ fees and costs) arising from:
                            <br/>
                            <font className={styles.MarginLeft}>(a)      Your use of and access to the Platform;</font>
                            <br/>
                            <font className={styles.MarginLeft}>(b)      Third-party claims who rely on Your representations to them based on the information made available through Our services;</font>
                            <br/>
                            <font className={styles.MarginLeft}>(c)       Your violation of any conditions in the Terms of Use and the Privacy Policy; or</font>
                            <br/>
                            <font className={styles.MarginLeft}>(d)      Your violation of any third party right, including without limitation any copyright, property, or privacy right.</font>
                        </p>
                        <h3 className={styles.h3}>26. Additional Terms</h3>
                        <p className={styles.p}>
                            1. We reserve the right at any time to modify, edit, delete, suspend or discontinue, temporarily or permanently the Service or Platform (or any portion thereof) with or without notice. You agree that we will not be liable to You or to any third party for any such modiﬁcation, editing, deletion, suspension or discontinuance of Platform.
                            <br/><br/>
                            2. This Agreement and any rights and licenses granted here under, may not be transferred or assigned by You, but may be assigned by Drishti IAS without restriction.
                            <br/><br/>
                            3. These Terms of Use together with the Privacy Policy as well as subscription details such as pricing tables and any other legal notices published by Drishti IAS on the Platform, shall constitute the entire agreement between You and Drishti IAS concerning the Platform and governs your use of the Service and use of the Platform, superseding any prior agreements between You and Drishti IAS with respect to the subject matter covered in these Terms of Use.
                            <br/><br/>
                            4. Our failure to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision. If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavour to give effect to the parties’ intentions as reﬂected in the provision, and the other provisions of this Agreement remain in full force and effect.
                            <br/><br/>
                            5. These Terms are governed by the laws of India. Any matters arising under these terms shall be subject to the exclusive jurisdiction of courts located in Delhi.
                        </p>
                        <h3 className={styles.h3}>27. Grievances</h3>
                        <p className={styles.p}>
                            In case of any grievance arising from the use of Platform, please contact us at help@groupdrishti.in.
                        </p>
                    </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TermsAndCondition;
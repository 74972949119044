// PATH: src/Components/Login/Essentials/SendOtp/SendOtp.js

import React, { useState, useEffect } from 'react';
import { ApiHitPost } from '../../../../Services/ApiHit';
import {jwtDecode} from 'jwt-decode';
import styles from './SendOtp.module.css';
import CustomCountryCodeDropDown from './Essentials/CustomCountryCodeDropDown/CustomCountryCodeDropDown';
import Loader from '../../../Includes/Loader/Loader';
import { useNavigate } from 'react-router-dom';

// const CLIENT_ID = '352037749668-hvj8gd4eaospu3fikm3l9sspbkcdp27s.apps.googleusercontent.com';
const CLIENT_ID = '709748746634-np4uti9hcad0d6894haeqhluv3da8lpr.apps.googleusercontent.com';

const SendOtp = ({ setMobileNumber, setResendTime, setShowOTPVerification, SelectedCountryCode, setSelectedCountryCode, setUserData, setIsNewUser, setStep, setGoogleLogin, handleLogin }) => {
  const [countryCodes, setCountryCodes] = useState([]);
  const [mobile, setMobile] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    // Fetch country codes from API
    ApiHitPost('/user/get_country_list')
      .then(response => {
        setCountryCodes(response.data);
        setSelectedCountryCode(response.data[0]); // Set default country code
      })
      .catch(error => {
        console.error('Error fetching country codes:', error);
      });
  }, [setSelectedCountryCode]);

  useEffect(() => {
    const handleGoogleSignIn = (response) => {
      const decoded = jwtDecode(response.credential);
  
      const { email } = decoded;
      setLoading(true);
      ApiHitPost('/auth/social_login', {
        email_id: email,
        social_token: response.credential,
        social_type: 2,
      }).then(response => {
        setLoading(false);
        if (response.status === 'SUCCESS') {
          setUserData(response.data);
          if (response.data.is_new_user || response.data.is_mobile_verified === 0) {
            localStorage.setItem('dla_web_student_u_temp', response.data.accessToken);
            localStorage.setItem('dla_web_student_r_temp', response.data.refreshToken);
            setIsNewUser(true);
            setGoogleLogin(true);
            setStep(3);
          } else {
            localStorage.setItem('dla_web_student_u', response.data.accessToken);
            localStorage.setItem('dla_web_student_r', response.data.refreshToken);
            localStorage.setItem('dla_web_student_first_name', response.data.first_name);
            localStorage.setItem('dla_web_student_last_name', response.data.last_name);
            localStorage.setItem('dla_web_student_profile_picture', response.data.profile_picture);
            localStorage.setItem('dla_web_student_selected_vertical', response.data.selected_vertical);
            localStorage.setItem('dla_web_student_email_id', response.data.email_id);
            localStorage.setItem('dla_web_student_gender', response.data.gender);
            const dla_web_student_redirect_back_to_login = localStorage.getItem('dla_web_student_redirect_back_to_login');
            handleLogin();
            if(dla_web_student_redirect_back_to_login == null){
              navigate('/');
            }else{
              localStorage.removeItem('dla_web_student_redirect_back_to_login');
              navigate(dla_web_student_redirect_back_to_login);
            }
          }
        } else {
          setError(response.message || 'Error during social login. Please try again.');
        }
      }).catch(error => {
        setLoading(false);
        setError((error.response && error.response.data && error.response.data.message) || 'Error during social login. Please try again.');
        console.error('Error during social login:', error);
      });
    };


    // Function to initialize Google Sign-In
    const initializeGoogleSignIn = () => {
      if (window.google && window.google.accounts) {
        window.google.accounts.id.initialize({
          client_id: CLIENT_ID,
          callback: handleGoogleSignIn,
        });

        window.google.accounts.id.renderButton(
          document.getElementById('google-signin-button'),
          { theme: 'outline', size: 'large' } // customization options
        );
      } else {
        console.error('Google Sign-In library not loaded.');
      }
    };

    // Wait for the Google Sign-In library to load
    const checkGoogleLibrary = setInterval(() => {
      if (window.google && window.google.accounts) {
        clearInterval(checkGoogleLibrary);
        initializeGoogleSignIn();
      }
    }, 100);

    // Clean up interval on component unmount
    return () => clearInterval(checkGoogleLibrary);
    
  }, [setUserData,navigate,setGoogleLogin,setIsNewUser,setStep]);

  const handleSendOTP = () => {
    if (!mobile) {
      setError('Mobile number is required.');
      return;
    }

    setLoading(true);
    setError('');
    setMobileNumber(mobile);

    // Send OTP API call
    ApiHitPost('/auth/send_otp', {
      country_id: SelectedCountryCode.id,
      mobile_number: mobile
    }).then(response => {
      setLoading(false);
      if (response.status === 'SUCCESS') {
        setResendTime(response.data.retry_in);
        setShowOTPVerification(true);
      } else {
        setError(response.message || 'Error sending OTP. Please try again.');
      }
    }).catch(error => {
      setLoading(false);
      setError((error.response && error.response.data && error.response.data.message) || 'Error sending OTP. Please try again.');
      console.error('Error sending OTP:', error);
    });
  };

  return (
    <div className={styles.sendOtpContainer}>
      {loading && <Loader />}
      {!loading && (
        <>
          <div className={styles.logo}>
            <img src="https://www.drishtiias.com/drishti/img/drishti_logo_new_eng.png" alt="Drishti Learning App" />
          </div>
          <h1>Drishti Learning App</h1>
          <div className={styles.progressBar}>
            <div className={`${styles.step} ${styles.active}`}>1</div>
            <div className={styles.step}>2</div>
            <div className={styles.step}>3</div>
          </div>
          <div className={styles.stepLabel}>
            <span className={`${styles.active} ${styles.Step1Text}`}>Enter Mobile Number</span>
            <span>Verify OTP</span>
            <span className={`${styles.Step3Text}`}>Provide Information</span>
          </div>
          <div className={styles.loginStep}>
            <label className={styles.LabelOfMobileNumber} htmlFor="countryCode">Please Enter Your Mobile Number</label>
            <div className={styles.mobileInputContainer}>
              <CustomCountryCodeDropDown
                options={countryCodes}
                selectedOption={SelectedCountryCode}
                onSelect={setSelectedCountryCode}
              />
              <input
                type="text"
                placeholder="Enter mobile number"
                value={mobile}
                onChange={e => setMobile(e.target.value)}
              />
            </div>
            {error && <div className={styles.errorMessage}>{error}</div>}
            <button className={styles.GetOtpButton} onClick={handleSendOTP}>Get OTP</button>
            <div className={styles.separator}>OR</div>
            <div id="google-signin-button" className={styles.googleSigninButton}></div>
          </div>
        </>
      )}
    </div>
  );
};

export default SendOtp;

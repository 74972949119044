import React, { useState } from "react";
import styles from "./NonPurchasedUserEmiInformation.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons";

const NonPurchasedUserEmiInformation = ({ emiInformation }) => {
  const [showEmiInformation, setShowEmiInformation] = useState(false);
  const showHideEmiInformation = () => {
    setShowEmiInformation(!showEmiInformation);
  }
  return (
    <div className={styles.EmiInformation}>
      <p className={styles.EmiText}>
        <span className={styles.EmiIcon}>✅ EMI Options Available</span>
        <span className={styles.EmiViewDetailText} onClick={showHideEmiInformation}>
          View Details
          {
            !showEmiInformation
            &&
            <FontAwesomeIcon
                icon={faChevronDown}
                className={styles.FaChevronDownUpIcon}
            />
          }
          {
            showEmiInformation
            &&
            <FontAwesomeIcon
                icon={faChevronUp}
                className={styles.FaChevronDownUpIcon}
            />
          }
        </span>
      </p>

      {
        showEmiInformation
        && 
        <div className={styles.EmiProgressContainer}>
          {emiInformation.map( (oneEmiInformation,index) => (
            <div
              key={index}
              className={`${styles.EmiStep} ${styles.EmiStepActive}`}
            >
              <div className={styles.EmiStepContent}>
                <div className={styles.EmiStepMarker}>
                  <span className={styles.EmiStepNumber}>{oneEmiInformation.emi_no}</span>
                </div>
                <div className={styles.EmiStepLabel}>
                  <div className={styles.EmiStepTitle}>
                    Rs. {oneEmiInformation.emi_mrp}
                  </div>
                  <div className={styles.EmiStepSubtitle}>
                    {oneEmiInformation.emi_validity} Days
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      }
    </div>
  );
};

export default NonPurchasedUserEmiInformation;

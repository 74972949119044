import React from "react";
import styles from './HierarchyElement.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";

const HierarchyElement = ({ hierarchyMapping }) => {

    function HandleBreadcrumbsVerticalClick(verticalId) {
        if(verticalId){
            localStorage.setItem("dla_web_student_selected_vertical",verticalId);
        }
        window.location = '/';
    }

    function HandleBreadcrumbsMiddleClick(NodeId) {
        let pathToBeUsed = [];
        for (let index = 0; index < hierarchyMapping.length; index++) {
            const oneData = hierarchyMapping[index];
            pathToBeUsed.push(oneData.id);
            if (oneData.id === NodeId) {
                break;
            }
        }

        if(pathToBeUsed.length > 0){
            localStorage.setItem("dla_web_student_selected_hierarchy",pathToBeUsed.join(','));
            window.location = '/explore';
        }
    }

    function HandleClick() {
        if(hierarchyMapping.length > 0){
            localStorage.setItem("dla_web_student_selected_vertical",hierarchyMapping[0]['id']);
        }
        window.location = '/';
    }
    
    return (
        <div className={styles.HierarchyHorizontalDiv}>
            {
                hierarchyMapping.map((oneHierarchy, index) => (
                    <div key={index} className={styles.OneHierarchyHorizontalDiv}>
                        {
                            index > 0 
                            &&
                            <FontAwesomeIcon 
                                key={`${index}_icon`}
                                icon={faChevronRight}
                            />
                        }
                        
                        <img 
                            src={oneHierarchy.category_thumbnail_image} 
                            alt=""
                            className={styles.HierarchyIcon}
                            onClick={(index+1) == hierarchyMapping.length ? () => {} : index == 0 ? () => {HandleBreadcrumbsVerticalClick(oneHierarchy.id)} : () => {HandleBreadcrumbsMiddleClick(oneHierarchy.id)}}
                        />
                        
                        <span 
                            onClick={(index+1) == hierarchyMapping.length ? () => {} : index == 0 ? () => {HandleBreadcrumbsVerticalClick(oneHierarchy.id)} : () => {HandleBreadcrumbsMiddleClick(oneHierarchy.id)}}
                        >
                            {oneHierarchy.category_name_english}
                        </span>
                    </div>
                ))
            }
        </div>
    );
};

export default HierarchyElement;

import React, { useEffect, useState } from "react";
import DashboardBanners from "./Essentials/DashboardBanners/DashboardBanners";
import DashboardGroups from "./Essentials/DashboardGroups/DashboardGroups";
import { ApiHitPost } from "../../Services/ApiHit"
import Loader from '../Includes/Loader/Loader'

const Dashboard = ({ SelectedVertical }) => {
    
    const [bannerInformation, setBannerInformation] = useState([]);
    const [groupInformation, setGroupInformation] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDashboardData = async () => {
            setLoading(true);
            try {
                const response = await ApiHitPost('/dashboard/get_dashboard_data', {
                    vertical_id: SelectedVertical
                });
                setBannerInformation(response.data.banners_information);
                setGroupInformation(response.data.group_information);
            } catch (error) {
                setError('Failed to load data');
            } finally {
                setLoading(false);
            }
        };

        fetchDashboardData();
    }, [SelectedVertical]); 

    if (loading) {
        return (
            <Loader />
        );
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    
    return (
        <>
            <div className="dashboard-banners-container">
                <DashboardBanners BannerInformation={bannerInformation} />
            </div>
            <div className="dashboard-groups-container">
                <DashboardGroups GroupInformation={groupInformation} />
            </div>
        </>
    )
}

export default Dashboard;
import React from 'react';
import { motion } from 'framer-motion';

const StatItem = ({ value, label }) => (
  <div className="flex flex-col items-center">
    <span className="text-white font-bold text-2xl md:text-3xl">{value}</span>
    <span className="text-white text-xs md:text-sm text-center">{label}</span>
  </div>
);

const StudentStatistics = () => {
  return (
    <div className="bg-blue-50 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="flex flex-col items-start"
        >
  
          <div className="flex items-center mb-4 justify-between w-full">
            <h2 className="text-xl font-bold text-gray-800">
              48 Students selected last month.
            </h2>
            <div className="flex items-center">
              <img
                src="/images/Dashboard/Medalicon.png"
                alt="48 Medal"
                className="w-16 h-100 md:w-20 md:h-100 object-contain"
                style={{ borderRadius: '50%' }}
              />
            </div>
          </div>

          <div className="w-full md:w-4/5 bg-blue-600 rounded-lg overflow-hidden">
            <div className="flex justify-between items-center py-4 px-6 md:px-10">
              <StatItem value="1500+" label="Students onboarded last month." />
              <StatItem value="301" label="Students in top Ranks" />
              <StatItem value="3552" label="Total Selections" />
            </div>
          </div>

        </motion.div>
      </div>
    </div>
  );
};

export default StudentStatistics;

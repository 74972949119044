import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPercent } from '@fortawesome/free-solid-svg-icons';
import './DiscountCoupons.css';

function DiscountCoupons({ coupons, onApplyCoupon, handleRemoveCoupon, appliedCoupon }) {
  const handleCouponClick = (coupon) => {
    if (coupon.applied) {
      // If the coupon is already applied, reload the page
      handleRemoveCoupon(coupon.coupon_id);
    } else {
      // If the coupon is not applied, call onApplyCoupon
      onApplyCoupon(coupon.coupon_id);
    }
  };

  return (
    <div className="discount-coupons">
      <h2>Discount Coupons</h2>
      {coupons.length > 0 ? (
        <div className="coupons-container">
          {coupons.map(coupon => {
            return (
              <div 
                key={coupon.coupon_id}
                className={`coupon ${coupon.applied ? 'applied' : ''}`}
              >
                <div className="coupon-info">
                  <FontAwesomeIcon icon={faPercent} className="coupon-icon" />
                  <div>
                    <h3>{coupon.coupon_title}</h3>
                    <p>{coupon.coupon_text_1}</p>
                  </div>
                </div>
                <button 
                  onClick={() => handleCouponClick(coupon)}
                >
                  {coupon.applied ? 'REMOVE' : 'TAP TO APPLY'}
                </button>
              </div>
            );
          })}
        </div>
      ) : (
        <img src={`${process.env.PUBLIC_URL}/images/no-coupon.png`} className="No-Coupon-Web" alt="No Coupon Available" />
      )}
    </div>
  );
}

export default DiscountCoupons;
import React from 'react';
import "./DownloadAppSection.css"

const DownloadAppSection = () => {
  return (
    <section className="bg-white py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto flex flex-col lg:flex-row items-center justify-between">

        <div className="lg:w-1/2 mb-10 lg:mb-0">
          <h2 className="text-4xl sm:text-4xl font-bold text-gray-900 mb-4">
            Prepare Anytime, Anywhere
          </h2>
          <p className="text-xl text-gray-600 mb-12">
            Download Drishti App right now.
          </p>
          <div className="flex flex-wrap gap-4">
            <a href="#" className="transition-transform hover:scale-105">
              <img src="/images/Dashboard/getitongoogleplay.png" alt="Get it on Google Play" className="h-12" />
            </a>
            <a href="#" className="transition-transform hover:scale-105">
              <img src="/images/Dashboard/new windows logo 2.png" alt="Get it on Windows" className="h-12" />
            </a>
            <a href="#" className="transition-transform hover:scale-105">
              <img src="/images/Dashboard/Mask group (5).png" alt="Download on the App Store" className="h-12" />
            </a>
          </div>
        </div>

        <div className="lg:w-1/2 relative flex justify-center">

          <div className="absolute inset-0 bg-gradient-to-br from-blue-200 to-indigo-100 rounded-full filter blur-2xl opacity-30 animate-pulse"></div>
          
          <div className="relative z-10 flex items-center">
          <img 
              src="/images/Dashboard/Frame 2147224467.png" 
              alt="Drishti App Screenshot 1" 
              className="w-40 h-auto lg:w-48 download-app-images transform -rotate-6 z-20"
            />
          <img 
              src="/images/Dashboard/Frame 2147224466.png" 
              alt="Drishti App Screenshot 2" 
              className="w-40 h-auto lg:w-48 -ml-18 download-app-images transform rotate-6"
            />
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default DownloadAppSection;

import React, { useState, useEffect } from 'react';
import styles from './MyCart.module.css';
import { FaTrash } from 'react-icons/fa';
import { ApiHitPost } from "../../Services/ApiHit";
import Loader from '../Includes/Loader/Loader';

const CartItem = ({ item, onRemove }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  
  const handleRemoveClick = () => {
    setShowConfirmation(true);
  };

  const handleConfirmRemove = () => {
    onRemove(item.product_id);
    setShowConfirmation(false);
  };

  const handleCancelRemove = () => {
    setShowConfirmation(false);
  };

  return (
    <div key={`${item.product_id}`} testt={item.product_id} className={styles.cartItemDiv}>
      <div className={styles.itemLeft}>
        <div className={styles.instructor}>
          <img className={styles.instructorImg} src={item.cover_image} alt="" />
        </div>
      </div>
      <div className={styles.itemRight}>
        <h3 className={styles.itemRightH3}>{item.product_title_english}</h3>
        <div className={styles.itemDetails}>
          <div className={styles.detailRow}>
            <span className={styles.detailRowSpan}>Validity</span>
            <span className={styles.detailRowSpan}>{item.validity} Months</span>
          </div>
          <div className={styles.detailRow}>
            <span className={styles.detailRowSpan}>Medium</span>
            <span className={styles.detailRowSpan}>{item.product_lang}</span>
          </div>
          <div className={styles.detailRow}>
            <span className={styles.detailRowSpan}>Category</span>
            <span className={styles.detailRowSpan}>
              {item.product_category_type_name}
            </span>
          </div>

          <div className={styles.detailRow}>
            <span className={`${styles.originalPrice}`}>
              ₹{item.product_mrp}/- 
            </span>
            <span className={`${styles.discountedPrice}`}>
              ₹{item.product_selling_price}/-
            </span>
          </div>

        </div>
      </div>
      <div className={styles.itemDeleteDiv}>
        <button className={styles.removeBtn} onClick={handleRemoveClick}>
            <FaTrash className={styles.removeBtnSvg} />
            <span>Remove</span>
        </button>
      </div>
      {showConfirmation && (
        <div className={styles.overlay}>
          <div className={styles.confirmationDialog}>
            <p>Are you sure you want to remove this item from the cart?</p>
            <br/>
            <div className={styles.confirmationDialogButtonDiv}>
              <button className={styles.confirmationDialogButtonYes} onClick={handleConfirmRemove}>Yes</button>
              <button className={styles.confirmationDialogButtonNo} onClick={handleCancelRemove}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const MyCart = () => {
  
  useEffect(() => {
    const dla_web_student_u = localStorage.getItem("dla_web_student_u");
    if (dla_web_student_u === null) {
      localStorage.setItem('dla_web_student_redirect_back_to_login',`/my-cart`);
      window.location.href = '/login';
    }
  }, []);

  const [myCartData,setMyCartData] = useState([]);
  const [myCartDataCount,setMyCartDataCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMyCartData = async () => {
      ApiHitPost('/user/get_cart_list', {
        type: 2
      }).then((response)=>{
        setMyCartData(response.data);
        setMyCartDataCount(response.data.length);
      }).catch((error)=>{
        console.log(error);
      }).finally(()=>{
        setLoading(false);
      });
    };
    fetchMyCartData();
  }, []);

  const removeItem = (id) => {
    console.log('id',id);
    ApiHitPost('/user/remove_from_cart', {
      product_id: id,
      type: 2
    }).then((response)=>{
      setMyCartData(response.data??[]);
      setMyCartDataCount(response.data?response.data.length:0);
    }).catch((error)=>{
      console.log(error);
    }).finally(()=>{
      setLoading(false);
    });
  };

  const checkOutClick = () => {
    window.location = '/payment';
  }

  if (loading) return <Loader />;

  return (
    <div className={styles.myCartDiv}>
      <h2 className={styles.myCartHeading}>
        My Cart [{myCartDataCount} Product{myCartDataCount > 1 ? 's' : ''}]
        {
          myCartDataCount > 0 
          &&
          <button onClick={() => checkOutClick()} className={styles.checkoutButton}>Check Out</button>
        }
      </h2>
      {myCartDataCount > 0 ? (
        <>
          {myCartData.map((item) => (
            <>
            <CartItem key={item.product_id} item={item} onRemove={removeItem} />
            </>
          ))}
          <button onClick={() => checkOutClick()} className={styles.checkoutButton}>Check Out</button>
        </>
      ) : (
        <div className={styles.emptyCartDiv}>
          <p>Your Cart is Empty... Add Items to Proceed with Checkout!</p>
        </div>
      )}
    </div>
  );
};

export default MyCart;

import React from 'react';
import styles from './AboutUs.module.css';

const AboutUs = () => {
    
    return (
        <>
            <div className={styles.container}>
                <div className={styles.row}>
                    <div className="col-md-12">
                        <div className={styles.aboutCon}>
                            <h3 className={styles.h3}>About Us</h3>
                            <p className={styles.des}>
                                Welcome to the Drishti Learning App, a cutting-edge platform designed to provide top-notch guidance right at your fingertips.
                            </p>

                            <p className={styles.des}>
                                Established on 1st November 1999, the Drishti Group has dedicated over two decades to empowering students and aspirants across India, becoming a beacon of excellence in the field of competitive exam preparation.
                            </p>

                            <p className={styles.des}>
                                The Drishti Learning App is meticulously crafted to meet the diverse needs of aspirants, offering a comprehensive suite of online programs and products. Our offerings are thoughtfully segregated into six verticals: UPSC, State PCS, Teaching Exams, Drishti Publications, CUET, and Law. Each vertical is designed to provide specialized and in-depth resources, ensuring that every aspirant receives tailored guidance and support.
                            </p>

                            <h3 className={styles.h3}>Our Programs</h3>

                            <p className={styles.des}>
                                We provide a wide range of programs, from interactive classroom sessions to rigorous test series and personalized mentorship. Our programs are designed to cover all aspects of preparation, helping aspirants develop a deep understanding of the subjects and excel in their exams. Whether you are aiming for the prestigious UPSC or preparing for state PCS examinations, our app offers you the best-in-class resources and guidance.
                            </p>

                            <p className={styles.des}>
                                For those aspiring to enter the teaching profession, we offer dedicated online guidance for both central and state-level teaching exams. Our comprehensive courses and practice tests are designed to equip you with the knowledge and confidence needed to succeed.
                            </p>

                            <h3 className={styles.h3}>Guidance for Diverse Aspirants</h3>

                            <p className={styles.des}>
                                Our app extends its support beyond traditional government service exams. We understand the unique needs of students transitioning from school to college, and our CUET preparation programs are designed to help them excel. For those aiming for a career in law, the Drishti Learning App is a top online platform for CLAT and various judicial services exams, providing detailed study materials and expert guidance.
                            </p>

                            <p className={styles.des}>
                                In addition to this, we provide specialized online guidance for aspirants preparing for various teaching exams at both central and state levels. Our tailored courses, mock tests, and expert insights ensure that teaching aspirants are well-prepared to meet the demands of their chosen exams and achieve their career goals.
                            </p>

                            <p className={styles.des}>
                                To further support our aspirants, we maintain dedicated websites for IAS, PCS, CUET, Law, and Teaching exams. These platforms offer a wealth of resources, including articles, study materials, and updates. Moreover, we run dedicated YouTube channels for each vertical, providing video lectures, tips, and motivational content to help you stay on track with your preparation.
                            </p>

                            <h3 className={styles.h3}>Drishti Publications</h3>

                            <p className={styles.des}>
                                A pillar of our success, Drishti Publications has been a cornerstone of the Drishti Group, delivering high-quality content for over two decades. Renowned for its accuracy and comprehensiveness, Drishti Publications has become the trusted choice for aspirants across all our verticals. Our books, study materials, and notes are crafted by experts to ensure that you have access to the best resources available.
                            </p>

                            <h3 className={styles.h3}>Why Choose Us?</h3>

                            <p className={styles.des}>
                                At Drishti Learning App, we are committed to your success. Our team of experienced educators, subject matter experts, and mentors work tirelessly to create an environment that fosters learning and growth. Our app is designed to be user-friendly and accessible, allowing you to learn at your own pace, anytime and anywhere.
                            </p>

                            <p className={styles.des}>
                                Join the Drishti Learning App and become part of a community that values excellence, dedication, and success. With our proven track record and unwavering commitment to quality education, you can trust us to guide you on your journey to achieving your dreams.
                            </p>

                            <p className={styles.des}>
                                Experience the difference with the Drishti Learning App—where your success is our mission.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutUs;
import React from "react";
import styles from "./TopLeftElement.module.css";
const TopLeftElement = ({ bannerImage, title }) => {
  return (
    <div className={styles.coursebannerspadding}>
      <div className={styles.courseBanner}>
        <img
          className={styles.courseBannerImage}
          src={bannerImage}
          alt={title}
        />
      </div>
    </div>
  );
};

export default TopLeftElement;

// PATH: src/Components/Login/Essentials/VerifyOtp/Essentials/OtpInputField/OtpInputField.js

import React from 'react';
import styles from './OtpInputField.module.css';

const OtpInputField = ({ value, valueLength, onChange }) => {
  
    const handleChange = (e, index) => {
    const newValue = e.target.value;

    if (/^\d*$/.test(newValue)) {
      const newOtp = [...value];
      newOtp[index] = newValue;
      onChange(newOtp.join(''));

      if (newValue && index < valueLength - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !value[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData('text');
    if (/^\d*$/.test(paste)) {
      const newOtp = [...value];
      for (let i = 0; i < valueLength; i++) {
        newOtp[i] = paste[i] || '';
      }
      onChange(newOtp.join(''));
    }
    e.preventDefault();
  };

  return (
    <div className={styles.otpInputContainer}>
      {Array(valueLength).fill('').map((_, index) => (
        <input
          key={index}
          id={`otp-input-${index}`}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          className={styles.otpInput}
          maxLength="1"
          value={value[index] || ''}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
        />
      ))}
    </div>
  );
};

export default OtpInputField;

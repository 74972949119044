import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from "./ProductInformation.module.css";
import TopLeftElement from "./Essentials/TopLeftElement/TopLeftElement";
import TopRightElement from "./Essentials/TopRightElement/TopRightElement";
import TabListElement from "./Essentials/TabListElement/TabListElement"
import TabContentElement from "./Essentials/TabContentElement/TabContentElement"
import HierarchyElement from "./Essentials/HierarchyElement/HierarchyElement";
import LockOverlayElement from "./Essentials/LockOverlayElement/LockOverlayElement";

import { ApiHitPost } from "../../Services/ApiHit";
import Loader from '../Includes/Loader/Loader';

const ProductInformation = ({ isLoggedInUser }) => {
  const { productId } = useParams();
  const [currentActiveTabId, setCurrentActiveTabId] = useState(false);
  const [currentActiveTabType, setCurrentActiveTabType] = useState(false);
  const [currentActiveTabData, setCurrentActiveTabData] = useState(false);
  const [productData, setProductData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const [lockMessage, setLockMessage] = useState('');
  const [lockEmiButtonToShow, setLockEmiButtonToShow] = useState(false);
  const [payEmiButtonText, setPayEmiButtonText] = useState('Pay EMI');
  const [payEmiNumber, setPayEmiNumber] = useState(1);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await ApiHitPost('/product/get_product_data', {
          product_id: Number(productId)
        });
        setProductData(response.data);

        // Now we need to set the active tab!
        if(response.data.tab_information && response.data.tab_information.length > 0){
          for (let index = 0; index < response.data.tab_information.length; index++) {
            const oneTab = response.data.tab_information[index];
            if([1,2,3,4].includes(oneTab.tab_type)){
              setCurrentActiveTabType(oneTab.tab_type)
              setCurrentActiveTabId(oneTab.tab_id);
              break;
            }
          }
        }
        
        if(response.data.is_locked){
          setIsLocked(true);
          setLockMessage(response.data.lock_message);
          if(response.data.lock_status === 3){
            setLockEmiButtonToShow(true);
          }
        }

      } catch (error) {
        console.log(error);
        setError('Failed to load data');
        // window.location.href = `/`;
      } finally {
        setLoading(false);
      }
    };
    fetchProductData();
  }, [productId]);

  const handleTabClick = (tabId,tabType,tabWebViewUrl) => {
    switch (tabType) {
      case 1:
      case 2:
      case 3:
      case 4:
        setCurrentActiveTabType(tabType)
        setCurrentActiveTabId(tabId);
        break;
      case 5:
        window.location.href = tabWebViewUrl;
        break;
      default:
        console.log('Invalid Tab Type');
        return;
    }
  }

  const handleAnotherEmiPaymentBuyNow = () => {
    if(isLoggedInUser){
      window.location.href = `/pay-emi/${productId}/${payEmiNumber}`;
    }else{
      localStorage.setItem('dla_web_student_redirect_back_to_login',`/pay-emi/${productId}/${payEmiNumber}`)
      window.location.href = '/login';
    }
  };

  useEffect(() => {
    if(currentActiveTabId){
      ApiHitPost('/product/get_product_tab_data',{
        product_id : parseInt(productId),
        tab_id : currentActiveTabId,
        level: 0,
        parent_id: 0
      }).then((response)=>{
        if(response.status === 'SUCCESS'){
          setCurrentLevel(0);
          setCurrentActiveTabData(response.data)
        }
      }).catch(error => {
        console.error('Error fetching country codes:', error);
      });
    }
  },[currentActiveTabId,productId]);

  if (loading) return <Loader />;
  if (error) return <div>{error}</div>;

  return (
    <div className={styles.ProductInformation}>
      {productData ? (
        <>
          <div className={styles.CourseHierarchyHeader}>
            
          </div>
          <HierarchyElement 
            hierarchyMapping = {productData.hierarchy_mapping}
          />
          <div className={styles.CourseHeader}>
            
            <TopLeftElement
              bannerImage={productData.basic_information.cover_image}
              title={productData.basic_information.product_title_english}
            />

            <TopRightElement 
              productData={productData} 
              isLoggedInUser={isLoggedInUser}
              productId={productId}
              setLoading={setLoading}
              setError={setError}
              payEmiButtonText={payEmiButtonText}
              setPayEmiButtonText={setPayEmiButtonText}
              handleAnotherEmiPaymentBuyNow={handleAnotherEmiPaymentBuyNow}
              payEmiNumber={payEmiNumber}
              setPayEmiNumber={setPayEmiNumber}
            />

          </div>

          
          {/* START OF OVERLAY */}
          <div className={styles.tabContainer}>
            <TabListElement
              listOfAllTabs={productData.tab_information}
              currentActiveTabId={currentActiveTabId}
              handleTabClick={handleTabClick}
            />
            
            {
              isLocked 
              && 
              <LockOverlayElement 
                lockMessage={lockMessage} 
                lockEmiButtonToShow={lockEmiButtonToShow}
                payEmiButtonText={payEmiButtonText}
                handleAnotherEmiPaymentBuyNow={handleAnotherEmiPaymentBuyNow}
              />
            }

            <TabContentElement
              productId={productId}
              currentActiveTabId={currentActiveTabId}
              currentActiveTabType={currentActiveTabType}
              currentActiveTabData={currentActiveTabData}
              setCurrentActiveTabData={setCurrentActiveTabData}
              currentLevel={currentLevel}
              setCurrentLevel={setCurrentLevel}
            />
          </div>
          {/* END OF OVERLAY */}

        </>
      ) : (
        <div>No course data available</div>
      )}
    </div>
  );
};

export default ProductInformation;

import React, { useState, useEffect } from 'react';
import './OurSuccess.module.css';

const SuccessMetric = ({ value, label, animationClass }) => {
  const [count, setCount] = useState(0);
  const endValue = parseInt(value.replace(/\D/g, '')); // Extract numeric part of value

  useEffect(() => {
    let startValue = 0;
    const duration = 2000; // Animation duration in ms
    const stepTime = Math.abs(Math.floor(duration / endValue));

    const interval = setInterval(() => {
      startValue += 1;
      setCount(startValue);

      if (startValue >= endValue) {
        clearInterval(interval);
      }
    }, stepTime);

    return () => clearInterval(interval);
  }, [endValue]);

  return (
    <div className={`bg-blue-50 rounded-lg shadow-sm p-4 flex flex-col items-center justify-center ${animationClass}`}>
      <span className="text-3xl font-bold text-blue-500 mb-2">
        {value.includes('+') ? `${count}+` : count}
      </span>
      <span className="text-sm text-gray-600">{label}</span>
    </div>
  );
};

const OurSuccess = () => {
  const metrics = [
    { value: '15000+', label: 'Students', animation: 'slide-left' },
    { value: '75%', label: 'Total success', animation: 'slide-right' },
    { value: '35', label: 'Main questions', animation: 'slide-up' },
    { value: '26', label: 'Chief experts', animation: 'slide-down' },
    { value: '16', label: 'Years of experience', animation: 'slide-left' },
  ];

  return (
    <div className="bg-white py-12">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-4">Our Success</h2>
        <p className="text-gray-600 text-center mb-8 max-w-2xl mx-auto">
          Ornare id fames interdum porttitor nulla turpis etiam. Diam vitae sollicitudin at nec nam et pharetra gravida. Adipiscing a quis ultrices eu ornare tristique vel nisi orci.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
          {metrics.map((metric, index) => (
            <SuccessMetric 
              key={index} 
              value={metric.value} 
              label={metric.label} 
              animationClass={metric.animation} 
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurSuccess;

import React, { useState, useEffect, useRef } from "react";
import "./Search.css";
import { ApiHitPost } from "../../../../../Services/ApiHit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faClose } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";

const Search = ({ SelectedVertical, onVerticalSelect }) => {

  const location = useLocation();
  // added check to show vertical on given url by @satyam
  const showVerticalDropdown = ['/', '/sample/lfkvsnl/dashboard/vsdjnvnkdsksvdl/vfksdjnsvdk'].includes(location.pathname);

  const [InputValue, SetInputValue] = useState("");
  const [Suggestions, SetSuggestions] = useState([]);
  const [TimeoutId, SetTimeoutId] = useState(null);
  const [Verticals, SetVerticals] = useState([]);
  const inputRef = useRef(null);
  const suggestionsRef = useRef(null);
  const SearchTextRef = useRef(null);

  const FetchSuggestions = async (SearchTerm) => {
    try {
      const response = await ApiHitPost("/dashboard/get_search_tags", {
        tag_initials: SearchTerm,
      });
      var suggestions = response.data.matching_tags_list.filter((tag) => tag); 
      if(suggestions.length <= 0){
        suggestions = [
          'No Result Found'
        ]
      }
      SetSuggestions(suggestions);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  useEffect(() => {
    if (TimeoutId) {
      clearTimeout(TimeoutId);
    }

    if (InputValue.trim().length >= 3) {
      const newTimeoutId = setTimeout(() => {
        // const LastWord = InputValue.trim().split(" ").pop();
        if (InputValue.length >= 3) {
          FetchSuggestions(InputValue);
        }
      }, 500); // Adjust the debounce delay as needed

      SetTimeoutId(newTimeoutId);
    } else {
      SetSuggestions([]);
    }

    return () => {
      if (TimeoutId) {
        clearTimeout(TimeoutId);
      }
    };
  }, [TimeoutId,InputValue]);

  useEffect(() => {
    const handleScroll = () => {
      SetSuggestions([]);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (inputRef.current && suggestionsRef.current) {
      const inputRect = inputRef.current.getBoundingClientRect();
      suggestionsRef.current.style.top = `${inputRect.bottom}px`;
      suggestionsRef.current.style.left = `${inputRect.left}px`;
      suggestionsRef.current.style.width = `${inputRect.width}px`;
    }
  }, [Suggestions]);

  const FetchVeticals = async () => {
    try {
      const response = await ApiHitPost("/hierarchy/get_vertical_list");
      const AllVerticals = response.data.data; 
      SetVerticals(AllVerticals);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  }
  
  useEffect(() => {
    FetchVeticals();
  }, []);

  const HandleChange = (e) => {
    console.log(e);
    SetInputValue(e.target.value);
  };

  useEffect(()=>{
    const dla_web_student_search_tag_text = localStorage.getItem('dla_web_student_search_tag_text');
    if(dla_web_student_search_tag_text !== null){
      HandleChange({target:{value: dla_web_student_search_tag_text}});
      localStorage.removeItem('dla_web_student_search_tag_text')
    }
  },[])

  const HandleSearchCrossClick = () => {
    SetInputValue('');
  }
  const HandleSearchClick = () => {
    if (SearchTextRef.current) {
      SearchTextRef.current.focus();
    }
  }

  const handleSearchSuggesstionClick = (searchTag) => {
    localStorage.setItem('dla_web_student_search_tag',searchTag);
    localStorage.setItem('dla_web_student_search_tag_text',searchTag);
    window.location.href = '/search';
  }

  return (
    <>
      {
        showVerticalDropdown 
        &&
        <div className="verticals_dropdown">
          <select value={SelectedVertical} onChange={onVerticalSelect} className="search-dropdown">
            {Verticals.map((OneVerticals, index) => (
              <option key={index} value={OneVerticals.hierarchy}>
                {OneVerticals.category_name_english}
              </option>
            ))}
          </select>
        </div>
      }
      <div className="search-container">
        <div className="search-wrapper" ref={inputRef}>
          <input
            type="text"
            ref={SearchTextRef}
            className="search-input"
            placeholder="Search for anything..."
            value={InputValue}
            onChange={HandleChange}
          />
          <button onClick={InputValue.length <= 0 ? HandleSearchClick : HandleSearchCrossClick} className="search-button">
            {InputValue.length <= 0 && (
              <FontAwesomeIcon icon={faSearch} />
            )}
            {InputValue.length > 0 && (
              <FontAwesomeIcon icon={faClose} />
            )}
          </button>
        </div>
        {Suggestions.length > 0 && (
          <div className="suggestions-container" ref={suggestionsRef}>
            {Suggestions.map((suggestion, index) => (
              <a onClick={() => handleSearchSuggesstionClick(suggestion)} key={index}>
                {suggestion}
              </a>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Search;

import React, { useState, useEffect } from "react";
import styles from './NewDashboard.module.css';
import HeroSection from "./Essentials/HeroSection/HeroSection";
import ExamSection from "./Essentials/ExamSection/ExamSection";
import OurSuccess from "./Essentials/OurSuccess/OurSuccess";
import StudentTestimonials from "./Essentials/StudentTestimonials/StudentTestimonials";
import OurCentres from "./Essentials/OurCentres/OurCentres";
import StudyMaterial from "./Essentials/StudyMaterial/StudyMaterial";
import StudentStatistics from "./Essentials/StudentStatistics/StudentStatistics";
import SampleClasses from "./Essentials/SampleClasses/SampleClasses";
import DownloadAppSection from "./Essentials/DownloadAppSection/DownloadAppSection";
import DashboardBanners from "../Dashboard/Essentials/DashboardBanners/DashboardBanners";
import { ApiHitPost } from "../../Services/ApiHit";
import Loader from '../Includes/Loader/Loader';

const NewDashboard = () => {
    const [SelectedVertical, setSelectedVertical] = useState(1);
    const [bannerInformation, setBannerInformation] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBannerData = async () => {
            setLoading(true);
            try {
                const response = await ApiHitPost('/dashboard/get_dashboard_data', {
                    vertical_id: SelectedVertical
                });
                setBannerInformation(response.data.banners_information);
            } catch (error) {
                setError('Failed to load banner data');
            } finally {
                setLoading(false);
            }
        };

        fetchBannerData();
    }, [SelectedVertical]);

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <div className="text-red-500">Error: {error}</div>;
    }

    return (
        <>
            <section className={styles.MainSection}>
                <DashboardBanners BannerInformation={bannerInformation} />
                <HeroSection />
                <ExamSection />
                <OurSuccess />
                <StudentTestimonials />
                <OurCentres />
                <StudyMaterial />
                <StudentStatistics />
                <SampleClasses />
                <DownloadAppSection />
            </section>
        </>
    );
};

export default NewDashboard;

import React, { useEffect, useState } from 'react';
import './SearchResult.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faLanguage } from '@fortawesome/free-solid-svg-icons';
import { ApiHitPost } from "../../Services/ApiHit";
import Loader from '../Includes/Loader/Loader'

const SearchResult = ({ isLoggedInUser }) => {

    const [searchResultData, setSearchResultData] = useState([]);
    const [searchResultTag, setSearchResultTag] = useState('');
    const [loading, setLoading] = useState(false);

    const truncateTitle = (text, wordLimit) => {
        const words = text.split(' ');
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };

    useEffect(() => {
        const dla_web_student_search_tag = localStorage.getItem('dla_web_student_search_tag');
        if (dla_web_student_search_tag === null) {
            window.location.href = '/';
        } else {
            setSearchResultTag(dla_web_student_search_tag);
        }
    }, []);

    useEffect(() => {
        if (searchResultTag) {  // Ensure that the tag is not empty
            const GetSearchResultData = () => {
                setLoading(true);
                ApiHitPost("/dashboard/get_products_by_tags", {
                    tag_name_string: searchResultTag,
                }).then((response) => {
                    setSearchResultData(response.data.matching_products_list);
                    localStorage.removeItem('dla_web_student_search_tag');
                }).catch((error) => {
                    console.error('Error fetching search results:', error);
                }).finally(() => {
                    setLoading(false);
                });
            }
            GetSearchResultData();
        }
    }, [searchResultTag]);  // Trigger API call when `searchResultTag` changes

    const handleViewDetails = (productId) => {
        window.open(`/product-information/${productId}`);
    };

    const handleBuyNowDetails = (productId) => {
        try {
            if (isLoggedInUser) {
                window.open(`/pay/${productId}`);
            } else {
                localStorage.setItem('dla_web_student_redirect_back_to_login', `/pay/${productId}`);
                window.open(`/login`);
            }
        } catch (error) {
            console.log(error);
        } finally {
            
        }
    }

    if (loading) {
        return (
            <Loader />
        );
    }

    return (
        <>
            {
                searchResultData.length === 0 
                &&
                <div className="course-list-container">
                    No Search Result Found!
                </div>
            }
            
            {
                searchResultData.length > 0 
                &&
                <div className="course-list-container">
                    {searchResultData.map((oneProductList,index) => (
                        <div key={index} className="course-list-wrapper">
                            <div className="course-list-header">
                                <h2>{oneProductList.heading_name}</h2>
                                {/* <a href="#" className="view-all">View All</a> */}
                            </div>
                            <div className="course-grid">
                                {oneProductList.data.map((course) => (
                                    <div key={course.id} className="course-card">
                                        <div className="course-info">
                                            <div className="course-banner">
                                                <img src={course.desc_header_image} alt={course.product_title_english} />
                                            </div>
                                            <div className="course-type">{truncateTitle(course.product_title_english, 3)}</div>
                                            <div className="category-main-div">
                                                <span className="category-btn">
                                                    {course.product_category_type_name}
                                                </span>
                                            </div>
                                            <br />
                                            <div className="course-details">
                                                <span>
                                                    <FontAwesomeIcon icon={faCalendar} style={{ color: '#3e67be' }} /> Validity: {course.validity} Months
                                                </span>
                                                <span>
                                                    <FontAwesomeIcon icon={faLanguage} style={{ color: '#3e67be' }} /> Medium: {course.product_lang}
                                                </span>
                                            </div>
                                            <div className="course-pricing">
                                                <span className="course-discounted-price">₹{course.product_selling_price}</span>
                                                <span className="course-original-price">₹{course.product_mrp}</span>
                                            </div>
                                            <div className={oneProductList.to_show_buy_now ? "course-actions" : "course-actions-left-align"}>
                                                <button
                                                    className="course-view-details"
                                                    onClick={() => handleViewDetails(course.product_id)}
                                                >
                                                    View Details
                                                </button>
                                                {
                                                    oneProductList.to_show_buy_now
                                                    &&
                                                    <button 
                                                        className="course-buy-now"
                                                        onClick={() => handleBuyNowDetails(course.product_id)}
                                                    >
                                                        Buy Now
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            }
        </>
    );
};

export default SearchResult;

import React from 'react';
import styles from './OrderDetails.module.css';

const OrderDetails = ({ transactions }) => (
  <div className={styles.orderDetails}>
    {transactions.map((transaction, index) => (
      <div key={index} className={styles.orderCard}>
        <div className={styles.orderHeader}>
          <div className={styles.orderInfo}>
            <div>
              <span className={styles.label}>Order Placed:</span>
              <span className={styles.value}>
                {new Date(transaction.created_at).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </span>
            </div>
            <div>
              <span className={styles.label}>Total:</span>
              <span className={styles.value}> ₹ {transaction.final_paid_amount}</span>
            </div>
          </div>
          <div className={styles.orderActions}>
            <span style={{ color: transaction.txn_status === 'Success' ? 'green' : 'red' }}>
              {transaction.txn_status === 'Success' ? '#Success' :
              transaction.txn_status === 'Pending' ? '#Failed' : '#Failed'}
            </span>
            <br />
            <span className={styles.orderId}>Order Id #{transaction.id}</span>
            {transaction.invoice_url ? (
              <>
                &nbsp;
                <a href={transaction.invoice_url} className={styles.viewInvoice}>View Invoice</a>
              </>
            ) : (
              <span className={styles.noInvoice}></span>
            )}
          </div>
        </div>

        <div className={styles.courseContainer}>
          <div className={styles.courseImage}>
            <img src={transaction.cover_image} alt={transaction.product_name_english} />
          </div>
          <div className={styles.courseDetails}>
            <h3 className={styles.productTitle}>{transaction.product_name_english}</h3>
            <p><span className={styles.label}>Validity:</span> {transaction.validity} Months</p>
            <p><span className={styles.label}>Medium:</span> {transaction.product_lang}</p>
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default OrderDetails;

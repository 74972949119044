import React, {useState, useEffect} from "react";
import "./PaymentSuccess.css";
import { useLocation } from 'react-router-dom';


const OrderConfirmation = (isLoggedInUser) => {

  const location = useLocation();
    const [postData, setPostData] = useState(null);

    useEffect(() => {
       // Check if no logged in user, redirect on login page
       const dla_web_student_u = localStorage.getItem("dla_web_student_u");
       if (dla_web_student_u === null) {
         localStorage.setItem('dla_web_student_redirect_back_to_login',`/my-cart`);
         window.location.href = '/login';
       }

        if (location.state) {
            // This is where you'd handle the "POST" data
            setPostData(location.state);
        }
    }, [isLoggedInUser,location.state]);
  return (
    <div className="order-confirmation">
      <h1>Order Confirmation</h1>
      <div className="confirmation-gif">
        <img src={`${process.env.PUBLIC_URL}/images/done.gif`} alt="Order Confirmed" />
      </div>
      <h2>Your Order has been accepted</h2>
      <p>Your item has been placed and is on it's way to being processed</p>
      <br/>
      <a href="/my-orders">
        <button className="go-to-orders-btn">Go to My Orders</button>
      </a>
      
    </div>
   
  );
};

export default OrderConfirmation;

import React from 'react';
import { Bars } from 'react-loader-spinner';
import "./Loader.css";

const Loader = () => {
  return (
    <div className="spinner-container">
      <div className="spinner">
        <Bars
          height="auto"
          width="100%"
          color="#14213d"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    </div>
  );
};

export default Loader;
import React from 'react';
import styles from './HeroSection.module.css';

const HeroSection = () => {
  return (
    <section className={styles.heroSection}>
      <div className={styles.content}>
        <h1 className={styles.title}>
          Most <span className={styles.trusted}>Trusted</span> Educational Platform
        </h1>
        <p className={styles.subtitle}>
          Unlock your potential and Learn From Renowned & Experienced Faculties
        </p>
      </div>
      <div className={styles.imageWrapper}>
        <img
          src="/images/Dashboard/vikassirimage.png"
          alt="Instructor"
          className={styles.instructorImage}
        />
      </div>
    </section>
  );
};

export default HeroSection;

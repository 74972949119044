import React, { useRef, useEffect } from "react";
import styles from './TabListElement.module.css';

const TabListElement = ({ listOfAllTabs, currentActiveTabId, handleTabClick }) => {
  const tabsRef = useRef(null);

  useEffect(() => {
    const tabsElement = tabsRef.current;
    let isDown = false;
    let startX;
    let scrollLeft;

    const handleMouseDown = (e) => {
      isDown = true;
      startX = e.pageX - tabsElement.offsetLeft;
      scrollLeft = tabsElement.scrollLeft;
    };

    const handleMouseLeave = () => {
      isDown = false;
    };

    const handleMouseUp = () => {
      isDown = false;
    };

    const handleMouseMove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - tabsElement.offsetLeft;
      const walk = (x - startX) * 2;
      tabsElement.scrollLeft = scrollLeft - walk;
    };

    tabsElement.addEventListener('mousedown', handleMouseDown);
    tabsElement.addEventListener('mouseleave', handleMouseLeave);
    tabsElement.addEventListener('mouseup', handleMouseUp);
    tabsElement.addEventListener('mousemove', handleMouseMove);

    return () => {
      tabsElement.removeEventListener('mousedown', handleMouseDown);
      tabsElement.removeEventListener('mouseleave', handleMouseLeave);
      tabsElement.removeEventListener('mouseup', handleMouseUp);
      tabsElement.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className={styles.courseFeatures}>
      <div className={styles.AllTabs} ref={tabsRef}>
        {listOfAllTabs.map((oneTab, index) => (
          <button
            key={index}
            className={`
              ${styles.OneTab}
              ${currentActiveTabId === oneTab.tab_id ? styles.OneTabActive : ""}
            `}
            onClick={() => handleTabClick(oneTab.tab_id, oneTab.tab_type, oneTab.web_view_url)}
          >
            <img 
              className={styles.OneTabIcon} 
              src={oneTab.tab_icon} 
              alt={oneTab.tab_name}
            />
            <span className={styles.OneTabName}>{oneTab.tab_name}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default TabListElement;
import React, { useEffect, useState } from "react";
import styles from "./TopRightElement.module.css";
import AddToCartButton from "./Essentials/AddToCartButton/AddToCartButton";
import BuyNowButton from "./Essentials/BuyNowButton/BuyNowButton";
import NonPurchasedUserEmiInfo from "./Essentials/NonPurchasedUserEmiInformation/NonPurchasedUserEmiInformation";
import OtherInformation from "./Essentials/OtherInformation/OtherInformation";
import PayEmiButton from "./Essentials/PayEmiButton/PayEmiButton";
import PurchasedUserEmiInformation from "./Essentials/PurchasedUserEmiInformation/PurchasedUserEmiInformation";
import { ApiHitPost } from "../../../../Services/ApiHit";

const TopRightElement = ({ productData, isLoggedInUser, productId, setLoading, setError, payEmiButtonText, setPayEmiButtonText, handleAnotherEmiPaymentBuyNow, payEmiNumber, setPayEmiNumber  }) => {

  const [showPayEmiButton, setShowPayEmiButton] = useState(false);
  const [showAddToCartAndBuyNowButton, setShowAddToCartAndBuyNowButton] = useState(false);
  const [isInCart, setIsInCart] = useState(false);
  const [purchasedUserEmiInformation,setPurchasedUserEmiInformation] = useState(false);
  const [nonPurchasedUserEmiInformation,setNonPurchasedUserEmiInformation] = useState(false);
  
  useEffect(()=>{

    // All the check to display PayEmi Button 
    const PayEmiButtonDisplayCheck = () => {
      if (productData.is_purchased_user && productData.purchased_emi_information.length > 0) {
        var nextEmiNumber = '';
        for (let index = 0; index < productData.purchased_emi_information.length; index++) {
          const one_purchased_emi_information = productData.purchased_emi_information[index];
          if(one_purchased_emi_information.status === 0){
            nextEmiNumber = one_purchased_emi_information.emi_no;
            break;
          }
        }
        if(nextEmiNumber !== ''){
          var ExtraButtonText = 'th';
          switch (parseInt(nextEmiNumber.toString().charAt(nextEmiNumber.length - 1))) {
            case 1:
              ExtraButtonText = 'st';
            break;
            case 2:
              ExtraButtonText = 'nd';
            break;
            case 3:
              ExtraButtonText = 'rd';
            break;
            default:
              ExtraButtonText = 'th';
              break;
          }
          setPayEmiButtonText(`PAY ${nextEmiNumber}${ExtraButtonText} EMI`)
          setPayEmiNumber(nextEmiNumber);
          setShowPayEmiButton(true);
        }else{
          setShowPayEmiButton(false);
        }
      }else{
        setShowPayEmiButton(false);
      }
    }

    // All the check to display AddToCart and BuyNow Button 
    const AddToCartAndBuyNowButtonDisplayCheck = () => {
      if (!productData.is_purchased_user && productData.basic_information.is_published === 1 && productData.basic_information.is_out_of_stock !== 1) {
        setShowAddToCartAndBuyNowButton(true);
      }else{
        setShowAddToCartAndBuyNowButton(false);
      }
      if (productData.is_in_cart) {
        setIsInCart(true);
      }else{
        setIsInCart(false);
      }
    }

    // All the check to display EMI Information of Purchased User
    const PurchasedUserEmiInformationDisplayCheck = () => {
      if (productData.is_purchased_user && productData.purchased_emi_information.length > 0) {
        setPurchasedUserEmiInformation(true);
      }else{
        setPurchasedUserEmiInformation(false);
      }
    }

    // All the check to display EMI Information of Non-Purchased User
    const NonPurchasedUserEmiInformationDisplayCheck = () => {
      if (!productData.is_purchased_user && productData.emi_information.length > 0) {
        setNonPurchasedUserEmiInformation(true);
      }else{
        setNonPurchasedUserEmiInformation(false);
      }
    }
    
    PayEmiButtonDisplayCheck();
    AddToCartAndBuyNowButtonDisplayCheck();
    PurchasedUserEmiInformationDisplayCheck();
    NonPurchasedUserEmiInformationDisplayCheck();
    
  },[productData,setPayEmiButtonText,setPayEmiNumber]);
  
  const handleFullPaymentBuyNow = () => {
    if(isLoggedInUser){
      window.location.href = `/pay/${productId}`;
    }else{
      localStorage.setItem('dla_web_student_redirect_back_to_login',`/pay/${productId}`)
      window.location.href = '/login';
    }
  };

  const handleEmiPaymentBuyNow = () => {
    if(isLoggedInUser){
      window.location.href = `/pay-emi/${productId}/1`;
    }else{
      localStorage.setItem('dla_web_student_redirect_back_to_login',`/pay-emi/${productId}/1`)
      window.location.href = '/login';
    }
  };

  const handleAddToCart = () => {
    try {
      setLoading(true);
      if (isLoggedInUser) {
        ApiHitPost('/user/add_to_cart', {
          product_id: Number(productId),
          type: 1,
        }).then(response => {
          if (response.status === 'SUCCESS') {
            setIsInCart(true);
          } else {
            setError(response.message ? response.message : 'Failed to Add the Product in the Cart');
          }
        })
        .catch(error => {
          console.error('Error fetching country codes:', error);
        }); 
      } else {
        localStorage.setItem('dla_web_student_redirect_back_to_login', `/product-information/${productId}`);
        window.location.href = '/login';
      }
    } catch (error) {
      console.log(error);
      setError('Failed to Add the Product in the Cart');
    } finally {
      setLoading(false);
    }
  };
  
  const handleGoToCart = () => {
    if(isLoggedInUser){
      window.location.href = '/my-cart';
    }else{
      localStorage.setItem('dla_web_student_redirect_back_to_login','/my-cart')
      window.location.href = '/login';
    }
  };

  return (
    <div className={styles.CoursePricingDiv}>
      <h3 className={styles.CourseTitleText}>{productData.basic_information.product_title_english}</h3>
      <h2 className={styles.PriceText}>
        ₹ {productData.basic_information.product_selling_price}{" "}
        {
          (productData.basic_information.product_selling_price !== productData.basic_information.product_mrp) 
          &&
          <span className={styles.OriginalPriceText}>₹ {productData.basic_information.product_mrp}</span>
        }
      </h2>
      
      {
        purchasedUserEmiInformation
        &&
        <PurchasedUserEmiInformation 
          emiInformation={productData.purchased_emi_information} 
        />
      }

      {
        nonPurchasedUserEmiInformation
        &&
        <NonPurchasedUserEmiInfo 
          emiInformation={productData.emi_information}
        />
      }
      
      <OtherInformation 
        otherInformation={productData.basic_information} 
      />
      
      <div className={styles.PrimaryButtons}>
        {
          showPayEmiButton 
          && 
          <PayEmiButton 
            payEmiButtonText={payEmiButtonText} 
            handleAnotherEmiPaymentBuyNow={handleAnotherEmiPaymentBuyNow}
          />
        }

        {
          showAddToCartAndBuyNowButton
          &&
          <>
            <AddToCartButton 
              isInCart={isInCart} 
              onAddToCart={handleAddToCart} 
              onGoToCart={handleGoToCart}
            />
            <BuyNowButton 
              onFullPaymentClick={handleFullPaymentBuyNow} 
              onEmiPaymentClick={handleEmiPaymentBuyNow} 
              toShowEmiButton={nonPurchasedUserEmiInformation}
            />
          </>
        }

      </div>
      
    </div>
  );
};

export default TopRightElement;

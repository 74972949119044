// PATH: src/Components/Login/Essentials/VerifyOtp/VerifyOtp.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiHitPost } from '../../../../Services/ApiHit';
import OtpInputField from './Essentials/OtpInputField/OtpInputField';
import styles from './VerifyOtp.module.css';
import Loader from '../../../Includes/Loader/Loader';

const VerifyOtp = ({ MobileNumber, ResendTime, SelectedCountryCode, userData, setUserData, setStep, isVerifyOtpAfterSocialLogin = false,handleLogin }) => {
  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState(ResendTime);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer(prevTimer => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);
    return () => clearInterval(countdown);
  }, [ResendTime]);

  const handleVerifyOTP = () => {
    if (otp.length !== 6) {
      setError('OTP should be 6 digits.');
      return;
    }

    setLoading(true);
    setError('');

    if(isVerifyOtpAfterSocialLogin){
      // Verify OTP API call
      const TempToken = localStorage.getItem("dla_web_student_u_temp");
      ApiHitPost('/auth/social/verify_otp', {
        otp: otp
      },{
        'Authorization': `Bearer ${TempToken}`
      }).then(response => {
        setLoading(false);
        if (response.status === 'SUCCESS') {
          const userData = response.data;

          console.log("user Data",userData);
          localStorage.setItem('dla_web_student_u', localStorage.getItem('dla_web_student_u_temp'));
          localStorage.setItem('dla_web_student_r', localStorage.getItem('dla_web_student_r_temp'));
          localStorage.setItem('dla_web_student_first_name', userData.first_name);
          localStorage.setItem('dla_web_student_last_name', userData.last_name);
          localStorage.setItem('dla_web_student_profile_picture', userData.profile_picture);
          localStorage.setItem('dla_web_student_selected_vertical', userData.selected_vertical);
          localStorage.setItem('dla_web_student_email_id', userData.email_id);
          localStorage.setItem('dla_web_student_gender', userData.gender);
          localStorage.setItem('dla_web_student_mobile_number', MobileNumber);
          localStorage.removeItem('dla_web_student_u_temp');
          localStorage.removeItem('dla_web_student_r_temp');
          const dla_web_student_redirect_back_to_login = localStorage.getItem('dla_web_student_redirect_back_to_login');
          handleLogin();
          if(dla_web_student_redirect_back_to_login == null){
            navigate('/');
          }else{
            localStorage.removeItem('dla_web_student_redirect_back_to_login');
            navigate(dla_web_student_redirect_back_to_login);
          }
        } else {
          setLoading(false);
          setError(response.data.message || 'Invalid OTP. Please try again.');
        }
      }).catch(error => {
        setLoading(false);
        setError((error.response && error.response.data && error.response.data.message) || 'Error verifying OTP. Please try again.');
        console.error('Error verifying OTP:', error);
      });
    }else{
      // Verify OTP API call
      ApiHitPost('/auth/verify_otp', {
        mobile_number: MobileNumber,
        otp: otp,
        country_id: SelectedCountryCode.id
      }).then(response => {
        setLoading(false);
        if (response.status === 'SUCCESS') {
          const userData = response.data;
          setUserData(userData);
          if (userData.is_new_user) {
            localStorage.setItem('dla_web_student_u_temp', userData.accessToken);
            localStorage.setItem('dla_web_student_r_temp', userData.refreshToken);
            setStep(3);
          } else {
            localStorage.setItem('dla_web_student_u', userData.accessToken);
            localStorage.setItem('dla_web_student_r', userData.refreshToken);
            localStorage.setItem('dla_web_student_first_name', userData.first_name);
            localStorage.setItem('dla_web_student_last_name', userData.last_name);
            localStorage.setItem('dla_web_student_profile_picture', userData.profile_picture);
            localStorage.setItem('dla_web_student_selected_vertical', userData.selected_vertical);
            localStorage.setItem('dla_web_student_email_id', userData.email_id);
            localStorage.setItem('dla_web_student_gender', userData.gender);
            localStorage.setItem('dla_web_student_mobile_number', MobileNumber);
            const dla_web_student_redirect_back_to_login = localStorage.getItem('dla_web_student_redirect_back_to_login');
            handleLogin();
            if(dla_web_student_redirect_back_to_login == null){
              navigate('/');
            }else{
              localStorage.removeItem('dla_web_student_redirect_back_to_login');
              navigate(dla_web_student_redirect_back_to_login);
            }
          }
        } else {
          setLoading(false);
          setError(response.data.message || 'Invalid OTP. Please try again.');
        }
      }).catch(error => {
        setLoading(false);
        setError((error.response && error.response.data && error.response.data.message) || 'Error verifying OTP. Please try again.');
        console.error('Error verifying OTP:', error);
      });
    }
    
  };

  const handleResendOTP = () => {
    setError('');
    setLoading(true);

    if(isVerifyOtpAfterSocialLogin){
      const TempToken = localStorage.getItem("dla_web_student_u_temp");
      // Resend OTP API call
      ApiHitPost('/auth/social/send_otp', {}, {
        'Authorization': `Bearer ${TempToken}`
      }).then(response => {
        setLoading(false);
        setTimer(response.data.retry_in);
      }).catch(error => {
        setLoading(false);
        setError('Error resending OTP. Please try again.');
        console.error('Error resending OTP:', error);
      });
    }else{
      // Resend OTP API call
      ApiHitPost('/auth/send_otp', {
        country_id: SelectedCountryCode.id,
        mobile_number: MobileNumber
      }).then(response => {
        setLoading(false);
        setTimer(response.data.retry_in);
      }).catch(error => {
        setLoading(false);
        setError('Error resending OTP. Please try again.');
        console.error('Error resending OTP:', error);
      });
    }
  };

  return (
    <div className={styles.otpVerification}>
      {loading && <Loader />}
      {!loading && (
        <>
          <div className={styles.logo}>
            <img src="https://www.drishtiias.com/drishti/img/drishti_logo_new_eng.png" alt="Drishti Learning App" />
          </div>
          <h1>Drishti Learning App</h1>
          <div 
          className={isVerifyOtpAfterSocialLogin? `${styles.SocialCaseprogressBar}`: `${styles.progressBar}`}
          >
            <div className={`${styles.step} ${styles.completed}`}>
              1
            </div>
            <div 
              className={isVerifyOtpAfterSocialLogin? `${styles.step} ${styles.completed}`: `${styles.step} ${styles.active}`}
            >
              2
            </div>
            <div 
              className={isVerifyOtpAfterSocialLogin? `${styles.step} ${styles.active}`: `${styles.step}`}
            >
              3
            </div>
          </div>
          <div className={styles.stepLabel}>
            <span className={`${styles.completed} ${styles.Step1Text}`}>
              {isVerifyOtpAfterSocialLogin ? <>Successfull Social Login</> : <>Enter Mobile Number</>}
            </span>
            <span className={styles.completed}>
              {isVerifyOtpAfterSocialLogin ? <>Provide Information</> : <>Verify OTP</>}
            </span>
            <span className={isVerifyOtpAfterSocialLogin? `${styles.completed} ${styles.Step3Text}`: `${styles.Step3Text}`}>
              {isVerifyOtpAfterSocialLogin ? <>Verify OTP</> : <>Provide Information</>}
            </span>
          </div>
          <div className={styles.verificationStep}>
            <span className={styles.verificationHeadingText}>Enter OTP sent to {MobileNumber}</span>
            <OtpInputField
              value={otp.split('')}
              valueLength={6}
              onChange={setOtp}
            />
            {error && <div className={styles.errorMessage}>{error}</div>}
            <button className={styles.VerifyOtpButton} onClick={handleVerifyOTP}>Verify OTP</button>
            {timer > 0 ? (
              <span className={styles.ResendOtpDisabledText}>Resend in {timer} seconds</span>
            ) : (
              <span className={styles.ResendOtpEnabledText} onClick={handleResendOTP}>Resend OTP</span>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default VerifyOtp;

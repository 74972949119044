import React from "react";
import styles from "./PurchasedUserEmiInformation.module.css";

// emiStages - length

const PurchasedUserEmiInformation = ({ emiInformation }) => {
  return (
    <div className={styles.EmiInformation}>
      <p className={styles.EmiText}>
        <span className={styles.EmiIcon}>✅</span> Purchased on EMI
      </p>
      <div className={styles.EmiProgressContainer}>
        {emiInformation.map( (oneEmiInformation,index) => (
          <div
            key={index}
            className={`${styles.EmiStep} ${
              oneEmiInformation.status === 1 ? styles.EmiStepActive : ""
            }`}
          >
            <div className={styles.EmiStepContent}>
              <div className={styles.EmiStepMarker}>
                <span className={styles.EmiStepNumber}>{index + 1}</span>
              </div>
              <div className={styles.EmiStepLabel}>
                <div className={styles.EmiStepTitle}>
                  {oneEmiInformation.emi_no}
                  {oneEmiInformation.emi_no === 1
                    ? "st"
                    : oneEmiInformation.emi_no === 2
                    ? "nd"
                    : oneEmiInformation.emi_no === 3
                    ? "rd"
                    : "th"}{" "}
                  EMI
                </div>
                <div className={styles.EmiStepSubtitle}>
                  {oneEmiInformation.status === 1 ? "Paid" : "Pending"}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PurchasedUserEmiInformation;

// PATH: src/Components/Login/Essentials/SendOtp/Essentials/CustomCountryCodeDropDown/CustomCountryCodeDropDown.js

import React, { useState } from 'react';
import styles from './CustomCountryCodeDropDown.module.css';

const CustomCountryCodeDropDown = ({ options, selectedOption, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option) => {
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <div className={styles.dropdown}>
      <div
        className={styles.dropdownToggle}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedOption.flag && <img src={selectedOption.flag} alt={selectedOption.country_name} />} +{selectedOption.country_code}
      </div>
      {isOpen && (
        <ul className={styles.dropdownMenu}>
          {options.map((option) => (
            <li
              key={option.id}
              className={styles.dropdownItem}
              onClick={() => handleOptionClick(option)}
            >
              {option.flag && <img src={option.flag} alt={option.country_name} />} +{option.country_code}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomCountryCodeDropDown;

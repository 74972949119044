import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const scaleUp = keyframes`
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
`;

// Styled Components
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

export const Title = styled.h1`
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  animation: ${scaleUp} 0.5s ease-in-out;
`;

export const Subtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  animation: ${fadeIn} 0.75s ease-in-out;
`;

export const CountdownContainer = styled.div`
  display: flex;
  justify-content: center;
  animation: ${fadeIn} 1s ease-in-out;
`;

export const CountdownItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
  font-size: 2rem;
  font-weight: 700;
`;

export const CountdownLabel = styled.span`
  font-size: 1rem;
  font-weight: 400;
`;
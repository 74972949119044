import React, { useState } from "react";
import styles from "./TabContentElement.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faSpinner,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { faAndroid, faApple, faWindows
 } from '@fortawesome/free-brands-svg-icons';

import { ApiHitPost } from "../../../../Services/ApiHit";

const TabContentElement = ({
  productId,
  currentActiveTabId,
  currentActiveTabType,
  currentActiveTabData,
  setCurrentActiveTabData,
  currentLevel,
  setCurrentLevel,
}) => {
  // State variables
  const [loading, setLoading] = useState(false);
  const [currentParentId, setCurrentParentId] = useState(0);
  const [previousParentId, setPreviousParentId] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(false);

  // Function to handle back navigation
  function backOnClick() {
    categoryOnClick(previousParentId, currentLevel - 1);
  }

  // Function to handle category click
  const categoryOnClick = (categoryId, levelToSend) => {
    if (currentActiveTabId) {
      setLoading(true);
      ApiHitPost("/product/get_product_tab_data", {
        product_id: parseInt(productId),
        tab_id: currentActiveTabId,
        level: levelToSend,
        parent_id: categoryId,
      })
        .then((response) => {
          if (response.status === "SUCCESS") {
            if (levelToSend - 1 > 0) {
              setPreviousParentId(currentParentId);
            }
            setCurrentLevel(levelToSend);
            setCurrentParentId(categoryId);
            setCurrentActiveTabData(response.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching country codes:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // Function to open PDF in a new window
  const pdfOnClick = (url) => {
    window.open(url);
  };

  // Function to toggle FAQ answers
  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const onImageError = (e) => {
    e.target.src = "/drishti_favicon.ico";
  };

  const downloadDlaApp = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const redirectToPlayStore = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.drishti.academy.app&hl=en_US",
      "_blank"
    );
  };

  const redirectToAppStore = () => {
    window.open("https://apps.apple.com/in/app/drishti-learning-app/id1604411528", "_blank");
  };

  const redirectToWindowsStore = () => {
    window.open("https://dla.drishtiias.com/windows", "_blank");
  };

  return (
    <>
      {/* Loading spinner */}
      {loading && (
        <span className={styles.backIconDiv}>
          <FontAwesomeIcon className={styles.spinnerIcon} icon={faSpinner} />
          <span>Loading...</span>
        </span>
      )}

      {/* Back button */}
      {!loading && currentLevel > 0 && (
        <span className={styles.backIconDiv} onClick={backOnClick}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>Back</span>
        </span>
      )}

      {/* For OVERVIEW TAB */}
      {currentActiveTabType === 2 &&
        currentActiveTabData.content_list &&
        currentActiveTabData.content_list.length > 0 && (
          <div className={styles.oneContainerDiv}>
            {currentActiveTabData.content_list.map((oneQuestion, index) => (
              <div key={index} className={styles.OneFaq}>
                <div
                  className={styles.OneFaqQuestion}
                  onClick={() => toggleAnswer(index)}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: oneQuestion.heading,
                    }}
                  ></div>
                  {/* <span>{oneQuestion.heading}</span> */}
                  <FontAwesomeIcon
                    icon={activeIndex === index ? faChevronUp : faChevronDown}
                    className={activeIndex === index ? styles.iconRotate : ""}
                  />
                </div>
                <div
                  className={`${styles.OneFaqAnswer} ${
                    activeIndex === index ? styles.open : ""
                  }`}
                >
                  <div
                    className={styles.OneFaqAnswerContent}
                    dangerouslySetInnerHTML={{
                      __html: oneQuestion.description,
                    }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        )}

      {/* For [PDF/VIDEO/TEST] categories */}
      {[1, 3, 4].includes(currentActiveTabType) &&
        currentActiveTabData.category_list &&
        currentActiveTabData.category_list.length > 0 && (
          <div className={styles.oneContainerDiv}>
            {currentActiveTabData.category_list.map((oneCategory, index) => (
              <div
                key={index}
                className={styles.oneCategorydiv}
                onClick={() =>
                  categoryOnClick(oneCategory.category_id, currentLevel + 1)
                }
              >
                <img
                  src={oneCategory.category_image_icon}
                  alt=""
                  className={styles.oneContentImage}
                  onError={onImageError}
                />
                <span className={styles.oneContentTotalText}>
                  <span className={styles.oneContentText}>
                    {oneCategory.category_title}
                  </span>
                  <br />
                  <span className={styles.oneContentSubText}>
                    {oneCategory.category_sub_text}
                  </span>
                </span>
              </div>
            ))}
          </div>
        )}

      {/* For PDF [END NODE] */}
      {currentActiveTabType === 1 &&
        currentActiveTabData.content_list &&
        currentActiveTabData.content_list.length > 0 && (
          <div className={styles.oneContainerDiv}>
            {currentActiveTabData.content_list.map((oneContent, index) => (
              <div
                key={index}
                className={styles.oneContentdiv}
                onClick={() => pdfOnClick(oneContent.pdf_file_url)}
              >
                <img
                  src={oneContent.pdf_thumbnail_url}
                  alt=""
                  className={styles.oneContentImage}
                  onError={onImageError}
                />
                <span className={styles.oneContentText}>
                  {oneContent.pdf_title}
                </span>
              </div>
            ))}
          </div>
        )}

      {/* For VIDEO [END NODE] */}
      {currentActiveTabType === 3 &&
        currentActiveTabData.content_list &&
        currentActiveTabData.content_list.length > 0 && (
          <div className={styles.oneContainerDiv}>
            {currentActiveTabData.content_list.map((oneContent, index) => (
              <div
                key={index}
                className={styles.oneContentdiv}
                onClick={downloadDlaApp}
              >
                <img
                  src={oneContent.video_thumbnail_url}
                  alt=""
                  className={styles.oneContentImage}
                  onError={onImageError}
                />
                <span className={styles.oneContentText}>
                  {oneContent.video_title}
                </span>
              </div>
            ))}
          </div>
        )}
      
      {/* For TEST [END NODE] */}
      {currentActiveTabType === 4 &&
        currentActiveTabData.content_list &&
        currentActiveTabData.content_list.length > 0 && (
          <div className={styles.oneContainerDiv}>
            {currentActiveTabData.content_list.map((oneContent, index) => (
              <div
                key={index}
                className={styles.oneContentdiv}
                onClick={downloadDlaApp}
              >
                <img
                  src="/drishti_favicon.ico"
                  alt=""
                  className={styles.oneContentImage}
                  onError={onImageError}
                />
                <span className={styles.oneContentText}>
                  {oneContent.test_series_name}
                </span>
              </div>
            ))}
          </div>
        )}
    
      {/* Download App Popup */}
      {showPopup && (
        <div className={styles.popupOverlay}>
          <div className={styles.popupContent}>
            <FontAwesomeIcon
              icon={faTimes}
              className={styles.closeIcon}
              onClick={closePopup}
            />
            <h2>Download Drishti Learning App</h2>
            <p>Experience the new Drishti Learning App on Android, iOS and Windows!!</p>
            <div className={styles.downloadButtons}>
              <button onClick={redirectToPlayStore} className={styles.androidButton}>
                <FontAwesomeIcon icon={faAndroid} />
                <span className={ styles.TextAndroid }>Android</span>
              </button>
              <button onClick={redirectToAppStore} className={styles.iosButton}>
                <FontAwesomeIcon icon={faApple} />
                <span className={styles.TextIos}>iOS</span>
              </button>
              <button onClick={redirectToWindowsStore} className={styles.windowsButton}>
                <FontAwesomeIcon icon={faWindows} />
                <span className={styles.WindowsText}>Windows</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TabContentElement;
// PATH: src/Components/Login/Login.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SendOtp from './Essentials/SendOtp/SendOtp';
import VerifyOtp from './Essentials/VerifyOtp/VerifyOtp';
import UserDetailsForm from './Essentials/UserDetailsForm/UserDetailsForm';
import './Login.css';

const Login = ({ handleLogin }) => {
  const [step, setStep] = useState(1);
  const [mobileNumber, setMobileNumber] = useState('');
  const [resendTime, setResendTime] = useState(0);
  const [SelectedCountryCode, setSelectedCountryCode] = useState('102');
  const [userData, setUserData] = useState(null);
  const [isGoogleLogin, setGoogleLogin] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    const dla_web_student_u_temp = localStorage.getItem("dla_web_student_u_temp");
    if (dla_web_student_u_temp !== null) {
      localStorage.removeItem("dla_web_student_u_temp");
    }
    const dla_web_student_r_temp = localStorage.getItem("dla_web_student_r_temp");
    if (dla_web_student_r_temp !== null) {
      localStorage.removeItem("dla_web_student_r_temp");
    }

    const dla_web_student_u = localStorage.getItem("dla_web_student_u");
    if (dla_web_student_u !== null) {
      const dla_web_student_redirect_back_to_login = localStorage.getItem('dla_web_student_redirect_back_to_login');
      if(dla_web_student_redirect_back_to_login == null){
        navigate('/');
      }else{
        localStorage.removeItem('dla_web_student_redirect_back_to_login');
        navigate(dla_web_student_redirect_back_to_login);
      }
    }
  }, [navigate]);

  return (
    <div className="login-container">
      <div className="login-image">
        <img src="https://www.drishtiias.com/images/uploads/1721901798_Group%201000016710.png" alt="Learning App" />
      </div>
      <div className="login-form">
        {step === 1 && (
          <SendOtp
            setMobileNumber={setMobileNumber}
            setResendTime={setResendTime}
            setShowOTPVerification={() => setStep(2)}
            SelectedCountryCode={SelectedCountryCode}
            setSelectedCountryCode={setSelectedCountryCode}
            setUserData={setUserData}
            setIsNewUser={setStep}
            setStep={setStep}
            setGoogleLogin={setGoogleLogin}
            handleLogin={handleLogin}
          />
        )}
        {step === 2 && (
          <VerifyOtp
            MobileNumber={mobileNumber}
            ResendTime={resendTime}
            SelectedCountryCode={SelectedCountryCode}
            userData={userData}
            setUserData={setUserData}
            setStep={setStep}
            isVerifyOtpAfterSocialLogin={false}
            handleLogin={handleLogin}
          />
        )}
        {step === 3 && (
          <UserDetailsForm
            userData={userData}
            isGoogleLogin={isGoogleLogin}
            setUserData={setUserData}
            setStep={setStep}
            handleLogin={handleLogin}
          />
        )}
      </div>
    </div>
  );
};

export default Login;

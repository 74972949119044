import React from "react";
import "./BillSummary.css";

function BillSummary({ emiNo, billSummaryData = {}, totalPrice = 0, appliedCoupon, addedAddOns = [], billSummary = {} }) {
  const {
    discount_amount = appliedCoupon?.coupon_value ?? 0,
    net_payble_amount = totalPrice
  } = billSummary;

  const totalCoursePrice = billSummaryData.course_basic_information?.reduce(
    (total, course) => total + (course.course_selling_price),
    0
  ) || 0;

  const totalAddOnsPrice = addedAddOns.reduce(
    (total, addon) => total + (addon.addon_sp || 0),
    0
  );

  const netTotal = totalCoursePrice + totalAddOnsPrice - discount_amount;

  const truncateTitle = (title) => {
    if (!title) return "Untitled Course";
    const words = title.split(' ');
    return words.length > 4 ? `${words.slice(0, 4).join(' ')}...` : title;
  };

  // console.log("applied coupon ", appliedCoupon);

  return (
    <div className="bill-summary">
      <h2>Bill Summary</h2>
      {billSummaryData.course_basic_information?.map((course, index) => (
        <div key={index} className="bill-item">
          <div className="course-info-billSummary">
            <span className="course-title">{truncateTitle(course.course_title_english)}</span>
            <span className="course-price-type">
              {emiNo ? `EMI - ${emiNo} Price` : ''}
            </span>
          </div>
          <span className="course-price">
            ₹{course.course_selling_price?.toFixed(2) || "0.00"}
          </span>
        </div>
      ))}
      {addedAddOns.map((addon) => (
        <div key={addon.id} className="bill-item">
          <span>{addon.addon_desc}</span>
          <span>₹{addon.addon_sp.toFixed(2)}</span>
        </div>
      ))}
      <div className="bill-item">
        <span>Discount</span>
        <span>-₹{billSummaryData.discount_amount}</span>
      </div>
      <div className="bill-total">
        <span>Total</span>
        <span>₹{billSummaryData.net_payble_amount}</span>
      </div>
    </div>
  );
}

export default BillSummary;